import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import _ from "lodash";
import { isDate } from "date-fns";
import { toast } from "react-toastify";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { withInitialAsync } from "hooks/useAsync";
import { formatDate, isAfter, parseDate } from "utils/date";
import * as recAPI from "api2/reconciliation";
import { FiltersContext } from "state/providers/FiltersProvider";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import { FormGroup } from "components/formik";
import SpecificationTable from "./SpecificationTable/SpecificationTable";
import FilterableLedgerTable from "./FilterableLedgerTable/FilterableLedgerTable";
import {
  getEmptySpec,
  getLedgerData,
  getNextAccountToReconciliation,
  getPrevAccountToReconciliation,
  getReconciliationData,
  mapVerToSpec,
  matchVerSpec,
} from "./helpers";
import ReconciliationSummary from "./ReconciliationSummary";
import ReconciliationSubmit from "./ReconciliationSubmit";

function ReconciliationPage() {
  const { company, parentPath } = useOutletContext();
  const { number, date } = useParams();
  const { t } = useTranslation("reports");
  const { updateFilter } = useContext(FiltersContext);
  const { getForDate } = useContext(FinancialYearDispatchContext);
  const parsedDate = parseDate(date);
  const financialYear = getForDate(parsedDate);

  useEffect(() => {
    const _date = parseDate(date);
    updateFilter({
      end: _date,
      fYear: getForDate(_date),
    });
  }, [getForDate, updateFilter, date]);

  if (!number || !isDate(parsedDate) || !financialYear) {
    return <Navigate to={`${parentPath}/balance`} />;
  }
  if (isAfter(parsedDate, new Date())) {
    toast.error(t("canNotReconcileFuture"));
    return <Navigate to={`${parentPath}/balance`} />;
  }

  return (
    <EnhancedReconciliationComponent
      companyId={company.id}
      accountNumber={parseInt(number, 10)}
      financialYear={financialYear}
      date={date}
      parentPath={parentPath}
    />
  );
}

function ReconciliationComponent({ companyId, accountNumber, financialYear, date, data, parentPath }) {
  const [ledger, setLedger] = React.useState(data.ledger);
  const navigate = useNavigate();
  const [reconciled, setReconciled] = React.useState(data.reconciliation.is_reconciled);
  const formikRef = React.useRef();
  const { t } = useTranslation("reports");
  const [routine, setRoutine] = useState("");

  React.useEffect(() => {
    const handleVerificationSaved = async (event) => {
      const savedVer = event.detail;
      if (!savedVer) {
        return;
      }
      if (!savedVer.booking_date) {
        return;
      }
      if (!isAfter(parseDate(savedVer.booking_date), parseDate(date))) {
        const accountTransactions = savedVer.transactions.filter((trans) => trans.account === accountNumber);
        if (accountTransactions.length) {
          getLedgerData(companyId, accountNumber, financialYear.id, financialYear.date_start, date, null).then(
            (newLedger) => {
              if (newLedger) {
                const {
                  values: { specifications },
                } = formikRef.current;
                matchVerSpec(specifications, newLedger.verifications);
                setLedger(newLedger);
              }
            }
          );
        }
      }
    };
    // After close verification modal we need to update ledger with new/changed verifications
    document.body.addEventListener("verification/saved", handleVerificationSaved);
    return () => document.body.removeEventListener("verification/saved", handleVerificationSaved);
  }, [companyId, accountNumber, financialYear.id, financialYear.date_start, date]);

  const handleAddEmptySpec = React.useCallback(
    ({ documents }) => {
      // Add empty draft specification into Specification Table
      const {
        values: { specifications },
        setFieldValue,
      } = formikRef.current;
      setFieldValue("specifications", [...specifications, getEmptySpec(documents, date)], false);
    },
    [date]
  );

  const onPaste = React.useCallback(
    (event) => {
      // Capture image from computer clipboard put into draft specification into Specification
      // table with image pre-selected
      const { items } = event.clipboardData || event.originalEvent.clipboardData;
      if (items.length) {
        const item = items[0];
        if (item.kind === "file") {
          const blob = item.getAsFile();
          handleAddEmptySpec({
            documents: [{ key: _.uniqueId("nd."), file: blob }],
          });
        }
      }
    },
    [handleAddEmptySpec]
  );

  const handleAddVerToSpec = React.useCallback((verification, index) => {
    // Add verification from ledger and put into draft specification into Specification table
    const {
      values: { specifications },
      setFieldValue,
    } = formikRef.current;
    const transactionId = verification.transaction_id;
    const verificationId = verification.id;
    const specKey = _.uniqueId(`sp${transactionId}-${verificationId}`);

    setLedger((state) => {
      return {
        ...state,
        verifications: state.verifications.map((ver) =>
          ver.transaction_id === transactionId ? { ...ver, spec: specKey } : ver
        ),
      };
    });
    setFieldValue("specifications", [...specifications, mapVerToSpec(verification, specKey)]);
  }, []);

  const handleVerCheck = React.useCallback((verification, index) => {
    const transactionId = verification.transaction_id;
    setLedger((state) => {
      return {
        ...state,
        verifications: state.verifications.map((ver) =>
          ver.transaction_id === transactionId ? { ...ver, is_checked: !ver.is_checked } : ver
        ),
      };
    });
  }, []);

  const handleRemoveSpec = React.useCallback((specification, index) => {
    // Removes specification from Specification Table. Possible to undo if specification has id set
    const {
      values: { specifications },
      setFieldValue,
    } = formikRef.current;
    if (specification.id) {
      const newSpecifications = specifications.map((spec) =>
        spec.id === specification.id
          ? {
              ...spec,
              is_deleted: true,
            }
          : spec
      );
      setFieldValue("specifications", newSpecifications);
    } else {
      setFieldValue("specifications", [...specifications.slice(0, index), ...specifications.slice(index + 1)]);
    }
    setLedger((state) => ({
      ...state,
      verifications: state.verifications.map((ver) => (ver.spec === specification.key ? { ...ver, spec: null } : ver)),
    }));
  }, []);

  const handleUndoRemoveSpec = React.useCallback((specification, index) => {
    // Undo remove specification (if id set)
    const {
      values: { specifications },
      setFieldValue,
    } = formikRef.current;
    const newSpecifications = specifications.map((spec) =>
      spec.id === specification.id
        ? {
            ...spec,
            is_deleted: false,
          }
        : spec
    );
    setFieldValue("specifications", newSpecifications);

    setLedger((state) => ({
      ...state,
      verifications: state.verifications.map((ver) =>
        ver.transaction_id === specification.transaction_id ? { ...ver, spec: specification.key } : ver
      ),
    }));
  }, []);

  const handleSaveRoutine = () => {
    recAPI.reconciliation
      .saveRoutine(companyId, accountNumber, routine)
      .then((response) => {
        toast.success(t("msg:saved"));
      })
      .catch((error) => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  const goToNextAccount = React.useCallback(
    async (isReconciled = true) => {
      const nextAccount = await getNextAccountToReconciliation(
        companyId,
        financialYear.id,
        financialYear.date_start,
        date,
        accountNumber,
        isReconciled
      );
      if (nextAccount && nextAccount !== accountNumber) {
        return `${parentPath}/balance/reconciliation/${nextAccount}/${formatDate(date)}`;
      }
      return `${parentPath}/balance`;
    },
    [accountNumber, financialYear.id, financialYear.date_start, companyId, date, parentPath]
  );

  const goToPrevAccount = React.useCallback(async () => {
    const prevAccount = await getPrevAccountToReconciliation(
      companyId,
      financialYear.id,
      financialYear.date_start,
      date,
      accountNumber
    );
    if (prevAccount && prevAccount !== accountNumber) {
      return `${parentPath}/balance/reconciliation/${prevAccount}/${formatDate(date)}`;
    }
    return `${parentPath}/balance`;
  }, [accountNumber, financialYear.id, financialYear.date_start, companyId, date, parentPath]);

  const goToAccount = async (next = true) => {
    let link;
    if (next) {
      link = await goToNextAccount(reconciled);
    } else {
      link = await goToPrevAccount();
    }
    navigate(link, { replace: false });
  };

  const onSubmit = (values, { setFieldValue }) => {
    const reconciliation = {
      reconciliation: {
        ...data.reconciliation,
        is_reconciled: reconciled,
        reconciled_date: formatDate(data.reconciliation.reconciled_date),
      },
      specifications: values.specifications.map((spec) => ({
        ...spec,
        amount: spec.amount || 0,
        account: accountNumber,
        financial_year: financialYear.id,
        booking_date: formatDate(spec.booking_date),
      })),
      // this will limit unnecessary db saving if checking not changed
      verifications: ledger.verifications.filter((ver) => ver.is_checked !== ver.baseChecked),
    };
    return recAPI.reconciliation
      .save(companyId, reconciliation)
      .then((response) => {
        toast.success(t("msg:saved"));
        const newSpecifications = values.specifications.map((spec, idx) => ({
          ...spec,
          id: response.data.specifications[idx],
        }));
        setFieldValue("specifications", newSpecifications);
        return response;
      })
      .catch((error) => {
        toast.error(t("msg:fixErrors"));
      });
  };

  const initialValues = {
    specifications: data.specifications,
  };
  if (!ledger) {
    return null;
  }
  return (
    <div onPasteCapture={onPaste}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        innerRef={formikRef}
        validateOnChange={false}
        validateOnBlur={false}
      >
        <Form>
          <Card>
            <Card.Body className="pt-1 pb-1">
              <h4>
                {t("reconciliationFor", {
                  number: accountNumber,
                  date: formatDate(date),
                })}
              </h4>
              <Row>
                <Col lg={6} sm={8}>
                  <FormGroup.Input
                    as="textarea"
                    name="routine"
                    rows={1}
                    placeholder={t("routinePlaceholder")}
                    defaultValue={data.reconciliation.routine}
                    onChange={(e) => {
                      setRoutine(e.target.value);
                    }}
                  />
                </Col>
                <Col lg={3} sm={8} className="content-vertical-center">
                  <button type="button" className="btn btn-sm btn-second ml-2" onClick={() => handleSaveRoutine()}>
                    {t("actions.saveRoutine")}
                  </button>
                </Col>
                <Col lg={3} sm={4} className="content-vertical-center justify-content-end">
                  <ButtonGroup className="float-right">
                    <Button variant="toggle" size="sm" onClick={() => goToAccount(false)}>
                      {t("common:actions.previous")}
                    </Button>
                    <Button variant="toggle" size="sm" onClick={goToAccount}>
                      {t("common:actions.next")}
                    </Button>
                  </ButtonGroup>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <SpecificationTable
            companyId={companyId}
            accountNumber={accountNumber}
            financialYear={financialYear}
            reconciliationDate={date}
            handleAddEmptySpec={handleAddEmptySpec}
            handleRemoveSpec={handleRemoveSpec}
            handleUndoRemoveSpec={handleUndoRemoveSpec}
          />
          <ReconciliationSummary
            ledger={ledger}
            baseReconciled={data.reconciliation.is_reconciled}
            currentReconciled={reconciled}
            setReconciled={setReconciled}
            goToNextAccount={goToNextAccount}
          />
          <ReconciliationSubmit accountNumber={accountNumber} date={date} />
        </Form>
      </Formik>
      <FilterableLedgerTable
        ledger={ledger}
        companyId={companyId}
        handleAddVerToSpec={handleAddVerToSpec}
        handleVerCheck={handleVerCheck}
      />
    </div>
  );
}

const EnhancedReconciliationComponent = withInitialAsync(
  ReconciliationComponent,
  ({ companyId, accountNumber, financialYear, date }) =>
    React.useCallback(
      (cancelToken) =>
        getReconciliationData(companyId, financialYear.id, accountNumber, financialYear.date_start, date, {
          cancelToken,
        }),
      [companyId, accountNumber, financialYear, date]
    ),
  {
    specifications: [],
    accountBalance: 0,
    ledger: null,
  },
  true
);

export default ReconciliationPage;
