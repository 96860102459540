// TODO: OPTIMIZATION, if ledger has a lot of rows then it loads really slowly, some kind of VirtualList needed?
import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Dropdown, Table } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { isWithinInterval } from "date-fns";
import qs from "qs";
import { useOutletContext, useParams } from "react-router-dom";

import * as reportAPI from "api2/reports";
import { AccountRangeFilter, DateRangeFilters, MultiSelectSimpleFilter } from "components/filters";
import { formatDate, isBefore } from "utils/date";
import TableLoader from "components/tables/btable/TableLoader";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import { formatMoneyRounding } from "utils/money";
import { useVerificationDispatch } from "hooks/useVerification";
import { TooltipActionButton } from "components/ui/buttons";
import { FiltersContext } from "state/providers/FiltersProvider";
import { RoundingSwitcher } from "components/ui/switchers";
import { filterActiveCC, filterActiveProjects, getDefaultRounding } from "utils/others";
import { useCompanyState } from "hooks/useCompany";
import { HasPermCode } from "components/perms";
import { codesForReports, codesForVerifications } from "components/perms/PermCodes";

const initialReport = [];

function LedgerReportPage() {
  const { company } = useOutletContext();
  const { t } = useTranslation("reports");
  const { number: accountNumber } = useParams();
  const { filters, updateFilter: setFilters } = useContext(FiltersContext);
  const ledgerPDFModal = useModal();
  const ledgerCSVModal = useModal();
  const periodPDFModal = useModal();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(initialReport);

  const [accounts, setAccounts] = useState({
    accountFrom: accountNumber,
    accountTo: accountNumber,
  });

  const [rounding, setRounding] = useState(getDefaultRounding);

  useEffect(() => {
    const { start, end, fYear, booking_date_order_desc } = filters;
    const signal = axios.CancelToken.source();

    function getReport() {
      if (
        fYear &&
        start &&
        end &&
        !isBefore(end, start) &&
        isWithinInterval(start, {
          start: fYear.date_start,
          end: fYear.date_end,
        }) &&
        isWithinInterval(filters.end, {
          start: fYear.date_start,
          end: fYear.date_end,
        }) &&
        accounts.accountFrom &&
        accounts.accountTo &&
        accounts.accountFrom <= accounts.accountTo
      ) {
        setLoading(true);
        reportAPI
          .ledgerReport(
            company.id,
            {
              date_from: formatDate(start),
              date_to: formatDate(end),
              financial_year: fYear.id,
              account_from: accounts.accountFrom,
              account_to: accounts.accountTo,
              cost_center_ids: filters.costcenter_ids || [],
              project_ids: filters.project_ids || [],
              with_open_balance: true,
              hide_empty_accounts: true,
              booking_date_order_desc,
            },
            {
              cancelToken: signal.token,
              paramsSerializer: (params) => {
                return qs.stringify(params, { indices: false });
              },
            }
          )
          .then((response) => {
            setReport(response.data);
            setLoading(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              setReport(initialReport);
              setLoading(false);
            }
          });
      } else {
        setReport(initialReport);
        setLoading(false);
      }
    }

    getReport();

    return () => {
      signal.cancel("aborted");
    };
  }, [accounts, company.id, filters]);

  const openLedgerPDFModal = () => {
    if (!accounts.accountFrom || !accounts.accountTo) {
      return toast.error(t("accountRangeRequired"));
    }
    const params = qs.stringify(
      {
        date_from: formatDate(filters.start),
        date_to: formatDate(filters.end),
        financial_year: filters.fYear.id,
        account_from: accounts.accountFrom,
        account_to: accounts.accountTo,
        cost_center_ids: filters.costcenter_ids || [],
        project_ids: filters.project_ids || [],
        with_open_balance: true,
        hide_empty_accounts: true,
        booking_date_order_desc: !!filters.booking_date_order_desc,
      },
      { indices: false }
    );
    return ledgerPDFModal.open(`/reports/ledger/pdf/?${params}`);
  };

  const openLedgerCSVModal = () => {
    if (!accounts.accountFrom || !accounts.accountTo) {
      return toast.error(t("accountRangeRequired"));
    }
    const params = qs.stringify(
      {
        date_from: formatDate(filters.start),
        date_to: formatDate(filters.end),
        financial_year: filters.fYear.id,
        account_from: accounts.accountFrom,
        account_to: accounts.accountTo,
        cost_center_ids: filters.costcenter_ids || [],
        project_ids: filters.project_ids || [],
        with_open_balance: true,
        hide_empty_accounts: true,
      },
      { indices: false }
    );

    return ledgerCSVModal.open(`/reports/ledger/csv/?${params}`);
  };
  const openPeriodisationPDFModal = (account) => {
    const params = qs.stringify(
      {
        date_to: formatDate(filters.end),
        account,
      },
      { indices: false }
    );
    return periodPDFModal.open(`/reports/periodisation/pdf/?${params}`);
  };

  return (
    <>
      <Card>
        <Card.Body className="pb-0">
          <div className="table-filters-group">
            <section className="table-filters-left">
              <Filters
                filters={filters}
                setFilters={setFilters}
                accounts={accounts}
                setAccounts={setAccounts}
                // setGlobalFilter={setGlobalFilter}
                t={t}
              />
            </section>
            <section className="table-filters-right">
              <div className="form-group">
                <label className="text-right">{t("common:actions.actions")}</label>
                <div>
                  <ButtonGroup>
                    <Dropdown>
                      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                        <i className="fas fa-file-pdf mr-1" /> {t("ver:perReport")} <i className="fas fa-caret-down" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item onClick={() => openPeriodisationPDFModal(1791)}>1791</Dropdown.Item>
                        <Dropdown.Item onClick={() => openPeriodisationPDFModal(2991)}>2991</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    <Button variant="secondary" onClick={openLedgerPDFModal}>
                      <i className="fas fa-file-pdf mr-1" /> {t("reportPDF")}
                    </Button>
                    <Button variant="secondary" onClick={openLedgerCSVModal}>
                      <i className="fas fa-file-csv mr-1" /> {t("reportCSV")}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </section>
          </div>
        </Card.Body>
      </Card>
      <Card className="reports profit-loss">
        {loading && <TableLoader />}
        <Card.Body className="pt-2">
          <RoundingSwitcher onRoundChange={setRounding} />
          <ReportTable
            report={report}
            companyId={company.id}
            filters={filters}
            setFilters={setFilters}
            rounding={rounding}
          />
        </Card.Body>
        {/* 
        {accountNumber && (
          <Card.Footer>
            <BackButton />
          </Card.Footer>
        )}
        */}
      </Card>
      {ledgerPDFModal.show && (
        <FileUrlModal fileUrl={ledgerPDFModal.data} companyId={company.id} handleClose={ledgerPDFModal.close} />
      )}
      {ledgerCSVModal.show && (
        <FileUrlModal
          fileUrl={ledgerCSVModal.data}
          companyId={company.id}
          downloadFileName="ledger_report.csv"
          handleClose={ledgerCSVModal.close}
        />
      )}
      {periodPDFModal.show && (
        <FileUrlModal fileUrl={periodPDFModal.data} companyId={company.id} handleClose={periodPDFModal.close} />
      )}
    </>
  );
}

function Filters({ filters, setFilters, accounts, setAccounts, t }) {
  const {
    costCenters: { asOptions: costOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();

  function onPeriodChange(start, end) {
    setFilters({ ...filters, start, end });
    // setGlobalFilter({ ...filters, start, end });
  }

  const onAccountsChange = ({ numberFrom, numberTo }) => {
    setAccounts({ accountFrom: numberFrom, accountTo: numberTo });
  };

  const onCCChange = (newCostCenter) => {
    setFilters({ ...filters, ...newCostCenter });
  };

  const onProjectChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };

  const showProjects = projectOptions.filter(filterActiveProjects).length !== 0;
  const showCostCenter = costOptions.filter(filterActiveCC).length !== 0;

  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <DateRangeFilters
          label={`${t("common:dates.period")}*`}
          dateStart={filters.start}
          dateEnd={filters.end}
          onChange={({ start, end }) => onPeriodChange(start, end)}
        />

        <AccountRangeFilter
          label={`${t("accounts")}*`}
          numberFrom={accounts.accountFrom}
          numberTo={accounts.accountTo}
          onChange={onAccountsChange}
        />
        <br />
        {showCostCenter && (
          <MultiSelectSimpleFilter
            label={t("common:costCenter")}
            defaultValue={filters.costcenter_ids}
            options={costOptions}
            isClearable={false}
            name="costcenter_ids"
            onFilter={onCCChange}
          />
        )}
        {showProjects && (
          <MultiSelectSimpleFilter
            label={t("common:project")}
            defaultValue={filters.project_ids}
            options={projectOptions}
            isClearable={false}
            name="project_ids"
            onFilter={onProjectChange}
          />
        )}
      </section>
    </div>
  );
}

function IcomingBalanceRow({ account, rounding }) {
  const { t } = useTranslation("reports");
  return (
    <tr>
      <td />
      <td>{t("initialBalance")}</td>
      <td colSpan={2} />
      <td>{formatMoneyRounding(account.initial_balance.debit, rounding)}</td>
      <td>{formatMoneyRounding(account.initial_balance.credit, rounding)}</td>
      <td>{formatMoneyRounding(account.initial_balance.sum, rounding)}</td>
    </tr>
  );
}
function ClosingBalanceRow({ account, rounding }) {
  const { t } = useTranslation("reports");
  return (
    <tr>
      <td />
      <td>{t("closingBalance")}</td>
      <td colSpan={2} />
      <td>{formatMoneyRounding(account.ending_balance.debit, rounding)}</td>
      <td>{formatMoneyRounding(account.ending_balance.credit, rounding)}</td>
      <td>{formatMoneyRounding(account.ending_balance.sum, rounding)}</td>
    </tr>
  );
}
// TODO MAREK MJ forReconciliation and onAddToSpecification not provided in any usage
function ReportTable({
  companyId,
  report,
  forReconciliation = false,
  onAddToSpecification,
  filters,
  setFilters,
  rounding = "3",
}) {
  const { open: openVerificationModal } = useVerificationDispatch();
  const { t } = useTranslation("reports");
  const canOpenVerModal = HasPermCode(codesForVerifications.enabled);
  const canReconciliation = HasPermCode(codesForReports.reconciliation);
  const hasNoTransactions = report.length === 0;
  return (
    <Table bordered className="sticky-header ledger-table">
      <thead>
        <tr>
          <th colSpan={2}>{t("common:account")}</th>
          <th colSpan={5} />
        </tr>
      </thead>
      <tbody>
        {hasNoTransactions && (
          <tr>
            <td colSpan={7}>{t("common:noTransactionsFound")}</td>
          </tr>
        )}
        {report.map((account) => (
          <React.Fragment key={account.number}>
            <tr className="parent-category--name sticky1">
              <th colSpan={2}>
                {account.number} - {account.name}
              </th>
              <th colSpan={5} />
            </tr>
            <tr className="sticky2">
              <th />
              <th>{t("common:verShort")}</th>
              <th
                style={{ width: 110, cursor: "pointer" }}
                onClick={() => {
                  setFilters({ ...filters, booking_date_order_desc: !filters.booking_date_order_desc });
                }}
              >
                {t("common:dates.date")}
                {filters.booking_date_order_desc ? <i className="fe-chevron-down" /> : <i className="fe-chevron-up" />}
              </th>
              <th>{t("common:description")}</th>
              <th>{t("common:money.debit")}</th>
              <th>{t("common:money.credit")}</th>
              <th>{t("common:money.balance")}</th>
            </tr>
            {!filters.booking_date_order_desc ? (
              <IcomingBalanceRow account={account} rounding={rounding} />
            ) : (
              <ClosingBalanceRow account={account} rounding={rounding} />
            )}
            {account.verifications.map((ver) => (
              <tr key={ver.transaction_id}>
                <td className="pb-0 pt-0">
                  {forReconciliation && canReconciliation && (
                    <TooltipActionButton
                      variant="info"
                      text={t("addToSpecifications")}
                      onClick={() => {
                        onAddToSpecification(ver);
                      }}
                      icon="fas fa-plus"
                    />
                  )}
                </td>
                <td>
                  <Button
                    variant="link"
                    className="p-0 m-0"
                    onClick={() => openVerificationModal(companyId, { id: ver.id })}
                    disabled={!canOpenVerModal}
                  >
                    {ver.verification}
                  </Button>
                </td>
                <td>{ver.booking_date}</td>
                <td>
                  {ver.title || "-"}
                  {ver.transaction_title && <small className="tran-title">({ver.transaction_title})</small>}
                </td>
                <td>{ver.debit ? formatMoneyRounding(ver.debit, rounding) : ""}</td>
                <td>{ver.credit ? formatMoneyRounding(ver.credit, rounding) : ""}</td>
                <td>{formatMoneyRounding(ver.balance, rounding)}</td>
              </tr>
            ))}
            {filters.booking_date_order_desc ? (
              <IcomingBalanceRow account={account} rounding={rounding} />
            ) : (
              <ClosingBalanceRow account={account} rounding={rounding} />
            )}
          </React.Fragment>
        ))}
      </tbody>
    </Table>
  );
}

export { ReportTable };
export default LedgerReportPage;
