import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { Card } from "react-bootstrap";

import CustomerImportButton from "components/forms/CustomerForm/CustomerImportModal";
import { FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import enCustomersTemplate from "assets/templates/csv/en/customersTemplate.csv";
import svCustomersTemplate from "assets/templates/csv/sv/customersTemplate.csv";
import DownloadTemplateButton from "components/ui/buttons/DownloadTemplateButton";

function ImportCSVCustomersStep({ companyId }) {
  const { fetchDetails } = useContext(OnboardingDispatchContext);
  const { t } = useTranslation("common");
  const formikProps = {
    initialValues: {
      importCustomers: false,
    },
    onSubmit: (values) => {
      return companyAPI.onboarding.customers.save(companyId).then(() => {
        fetchDetails(true);
      });
    },
  };

  const onImportSuccess = (data) => {
    fetchDetails(true);
    toast.success(`${t("msg:uploaded")} - ${data.count || 0}`);
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, handleSubmit }) => {
        return (
          <Card className="import-csv-customers">
            <Card.Body>
              <StepHeader
                rightComponent={
                  values.importCustomers ? (
                    <DownloadTemplateButton
                      templatePaths={{ en: enCustomersTemplate, sv: svCustomersTemplate }}
                      tooltipTranslationKey="onboarding.downloadTemplate"
                      downloadFileName="customersTemplate"
                      buttonTitle={t("company:onboarding.downloadTemplate")}
                    />
                  ) : (
                    <SaveButton disabled={isSubmitting} onSave={handleSubmit} />
                  )
                }
              />
            </Card.Body>

            <Card.Body>
              <Form noValidate>
                <FormGroup.YesNoToggle
                  className="btn-toggle-grey"
                  name="importCustomers"
                  label={t("company:onboarding.wantImportCustomer")}
                />
              </Form>
              {values.importCustomers && (
                <CustomerImportButton size="lg" variant="blue" companyId={companyId} onSuccess={onImportSuccess} />
              )}
            </Card.Body>
          </Card>
        );
      }}
    </Formik>
  );
}

const EnhancedImportCSVCustomersStep = withInitialAsync(
  ImportCSVCustomersStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.customers.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedImportCSVCustomersStep;
