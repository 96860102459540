import React, { useContext, useMemo, useState } from "react";
import cx from "classnames";
import { DraggableModalDialog } from "components/modals/DraggableModalDialog";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { differenceInCalendarDays } from "date-fns";
import { toast } from "react-toastify";
import { Scrollbars } from "react-custom-scrollbars";
import { SelectSimpleFilter } from "components/filters";
import { StatusListStateContext } from "state/providers/StatusListProvider";
import useInitialAsync from "hooks/useInitialAsync";
import { formatDate, formatDatetime } from "utils/date";
import Loader from "components/ui/loaders/Loader";
import * as selectAPI from "api2/selects";
import * as statusAPI from "api2/status-list";
import { useCompanyDispatch } from "hooks/useCompany";
import "./EventDetailModal.scss";
import { getInitials } from "./helpers";
import EventSelect from "./EventSelect";
import { EventChecklist } from "../StatusListChecklist";

function CommentBox({ comments }) {
  const { t } = useTranslation("common");

  if (!comments.length) {
    return (
      <article className="comments-item">
        <p className="mb-0">{t("noCommentsYet")}</p>
      </article>
    );
  }
  return (
    <Scrollbars style={{ height: 200 }} className="comment-list">
      {comments.map((comment, i) => (
        <article key={`comment.${comment.id}`} className="comment-item">
          <div className="comment-content">
            <div className="comment-initial">
              <div className="badge">{getInitials(comment.created_by_name)}</div>
            </div>
            <div>{comment.text}</div>
          </div>
          <div className="comment-date">{formatDatetime(comment.created)}</div>
        </article>
      ))}
    </Scrollbars>
  );
}

function CommentWidget({ eventId }) {
  const { t } = useTranslation();
  const { updateEvent } = useContext(StatusListStateContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const {
    loading,
    item: comments,
    set: setComments,
  } = useInitialAsync(() => statusAPI.events.comments.list(eventId), []);
  const ref = React.useRef();

  const newMessage = () => {
    const text = ref.current.value.trim();
    if (!text) {
      toast.error(t("errors.messageRequired"), { autoClose: 3000 });
      return;
    }
    setSubmitting(true);
    statusAPI.events.comments
      .create(eventId, text)
      .then((res) => {
        ref.current.value = "";
        setComments([res.data, ...comments]);
        updateEvent(eventId, { comments: [res.data.id] });
        toast.success(t("msg:saved"), { autoClose: 3000 });
      })
      .catch(() => {
        toast.error(t("errors.sthWentWrong"), { autoClose: 3000 });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onRemove = async (index, comment) => {
    await statusAPI.events.comments.remove(eventId, comment.id).then(() => {
      setComments([...comments.slice(0, index), ...comments.slice(index + 1)]);
    });
  };

  return (
    <div className="comments">
      <label>{t("common:comments")}</label>
      <div className="comments-box">
        {loading ? <Loader /> : <CommentBox comments={comments} userId={1} onRemove={onRemove} />}
        <div className="comment-prompt">
          <label>{t("common:comment")}</label>
          <textarea className="form-control" rows={3} autoFocus ref={ref} />
          <Button variant="primary" size="sm" disabled={isSubmitting} onClick={newMessage}>
            {t("common:comment")}
          </Button>
        </div>
      </div>
    </div>
  );
}

function AssigneeSelect({ event }) {
  const { t } = useTranslation();
  const { updateEvent } = useContext(StatusListStateContext);
  const { loading, item } = useInitialAsync(
    ({ cancelToken }) =>
      selectAPI
        .agencyConsults(
          {
            company: event.company_id,
          },
          { cancelToken }
        )
        .then((users) => {
          return users.map((user) => ({ ...user, label: user.name, value: user.id }));
        }),
    [],
    [event.company_id]
  );

  const onUpdate = ({ assignee }) => {
    statusAPI.events
      .updateAssignee(event.id, assignee)
      .then(() => {
        updateEvent(event.id, {
          assignee_id: assignee,
          assignee_name: item.find((user) => user.id === assignee).name,
        });
      })
      .catch(() => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };

  if (loading) {
    return null;
  }

  return (
    <SelectSimpleFilter
      defaultValue={event.assignee_id}
      label={t("officeSupport:config.assignee")}
      onFilter={onUpdate}
      name="assignee"
      options={item}
    />
  );
}

function EventPeriodBadge({ event }) {
  const { statusIndicator } = event;
  const finalised = statusIndicator.index === statusIndicator.max;
  const diffDays = differenceInCalendarDays(event.deadline, new Date());
  const showWarn = !finalised && diffDays <= 5;
  const showDanger = !finalised && diffDays <= 0;
  return (
    <div className="event-period-tag">
      <div
        className={cx("period-tag", {
          green: finalised,
          purple: statusIndicator.index > 1 && statusIndicator.index < statusIndicator.max,
          "border-warn": showWarn,
          "border-danger": showDanger,
        })}
      >
        <span>{event.periodIndicator}</span>
      </div>
    </div>
  );
}

function EventDetailModal({ event, handleClose }) {
  const { t, i18n } = useTranslation();
  const isEnglish = useMemo(() => i18n.language === "en", [i18n.language]);
  const { selectCompany: selectCompanyAction } = useCompanyDispatch();

  const onClose = () => {
    handleClose();
  };

  const selectCompany = async () => {
    await selectCompanyAction(event.company_id);
    window.open("/office-support-companies/status-list", "_blank");
  };
  console.log("e", event);
  return (
    <Modal
      animation={false}
      scrollable
      show
      onHide={onClose}
      size="xl"
      dialogClassName="status-list-detail-modal"
      dialogAs={DraggableModalDialog}
    >
      <Modal.Header closeButton>
        <div>
          <h1>
            {isEnglish ? event.activity.name_en : event.activity.name_sv}
            <EventPeriodBadge event={event} />
          </h1>
          <h2>{event.company_name}</h2>
          <h3>
            {t("common:dates.deadline")} {formatDate(event.deadline)}
          </h3>
        </div>
      </Modal.Header>
      <Modal.Body style={{ minHeight: 300 }}>
        <div className="content">
          <div className="description">
            <label className="title-label">{t("common:description")}</label>
            <Scrollbars className="body" style={{ height: 540 }}>
              <p>{event.activity.note || t("common:noDescriptionYet")}</p>
            </Scrollbars>
          </div>
          {event.activity.checklist.length > 0 ? (
            <div className="checklist">
              <label className="title-label">{t("common:checklist")}</label>
              <EventChecklist event={event} companyId={event.company_id} index={0} />
            </div>
          ) : null}
          <div className="form">
            <div className="form-group extended">
              <label className="title-label">{t("common:statuses.status")}</label>
              <EventSelect
                eventId={event.id}
                deadline={event.deadline}
                label={t("common:statuses.status")}
                periodIndicator={event.periodIndicator}
                current={event.statusIndicator.current}
                options={event.statusIndicator.statuses}
                extended
                inModal
              />
            </div>
            <AssigneeSelect event={event} />
            <CommentWidget eventId={event.id} />
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" className="configure-btn" onClick={selectCompany}>
          <i className="fe-settings" /> {t("common:actions.configure")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default EventDetailModal;
