import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import cx from "classnames";

import BTable from "components/tables/btable";
import { formatDate, formatMonth } from "utils/date";
import Filters from "./EmployeeAbsenceFilters";
import Actions from "./EmployeeAbsenceActions";

function EmployeeAbsenceTable({ companyId, allowedModes, forStatus, selectable = false }) {
  const { t } = useTranslation("common");
  const headers = useMemo(
    () => [
      {
        field: "user_name",
        label: t("common:employee"),
        sortField: "user__last_name",
      },
      {
        field: "month_holder",
        label: t("common:dates.month"),
        render: (values) => formatMonth(values.month_holder),
      },
      {
        field: "start_date",
        label: t("common:dates.dateFrom"),
        canSort: false,
        render: (values) => (
          <>
            {values.tasks.map((task) => (
              <span className="d-block" key={task.id}>
                {formatDate(task.started)}
              </span>
            ))}
          </>
        ),
      },
      {
        field: "end_date",
        label: t("common:dates.dateTo"),
        canSort: false,
        render: (values) => (
          <>
            {values.tasks.map((task) => (
              <span className="d-block" key={task.id}>
                {task.stopped ? formatDate(task.stopped) : ""}
              </span>
            ))}
          </>
        ),
      },
      {
        field: "reason",
        label: t("common:reason"),
        canSort: false,
        render: (values) => (
          <>
            {values.tasks.map((task) => (
              <span className="d-block" key={task.id}>
                {task.task_title}
              </span>
            ))}
          </>
        ),
      },
      {
        field: "description",
        label: t("common:description"),
        canSort: false,
        render: (values) =>
          values.tasks.length
            ? values.tasks.map((task) => (
                <span className="d-block" key={task.id}>
                  {task.task_comment || "-"}
                </span>
              ))
            : t("salary:noAbsence"),
      },
    ],
    [t]
  );

  const getRowProps = (row) => {
    return {
      className: cx(row.status || "draft", {
        "has-message": !!row.comments.length,
      }),
    };
  };

  return (
    <BTable
      headers={headers}
      selectable={selectable}
      FiltersComponent={<Filters companyId={companyId} allowedModes={allowedModes} forStatus={forStatus} />}
      renderActions={(row) => <Actions companyId={companyId} row={row} />}
      renderTotals={null}
      getRowProps={getRowProps}
    />
  );
}

export default EmployeeAbsenceTable;
