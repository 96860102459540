import React, { useContext, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Col, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import { FieldArray, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import cx from "classnames";
import _ from "lodash";

import * as verAPI from "api2/verification";
import { confirmExecute } from "components/modals/ConfirmModal";
import { FormGroup, TableGroup } from "components/formik";
import { HistoryButton, PaymentSpecificationPdfButton, TooltipActionButton } from "components/ui/buttons";
import { formatMoney, roundMoney } from "utils/money";
import { collectionSum } from "utils/list";
import { CheckboxFilter, SelectSimpleFilter } from "components/filters";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { BalanceStateContext } from "state/providers/BalanceProvider";
import { handleActionErrors } from "api/errors";
import { toast } from "react-toastify";
import { appendAutoAccounts, emptyTransaction } from "utils/verification";
import { periodisationAccounts, periodisationToogleAccounts } from "utils/periodisation";
import { isAssetToAutoAddBasedOnTransactions, isDepreciationTransactionIn } from "utils/assets";
import { HasPermCode } from "components/perms";
import { codesForInventoryRegister } from "components/perms/PermCodes";
import { CCAddModal, ProjectAddModal } from "components/modals";
import useModal from "hooks/useModal";
import { formatISO } from "date-fns";
import { useCompanyDispatch } from "hooks/useCompany";
import TransactionBalance from "./TransactionBalance";
import { BaseVerificationsPreviewModal } from "../VerificationPreviewModals";
import PeriodisationEndUndoStopPreviewsModal from "../VerificationPreviewModals/PeriodisationEndUndoStopPreviewsModal";
import PeriodisationActionModal from "./PeriodisationActionModal";

const formatCreateLabel = (t, isFor = "") => {
  return (
    <div style={{ textAlign: "center" }}>
      <i className="fas fa-plus" style={{ color: "#348CD4" }} />{" "}
      {isFor === "project" ? t("company:actions.addProject") : t("company:actions.addCostCenter")}
    </div>
  );
};

const getTransactionPayload = (transaction) => {
  return {
    is_deleted: transaction.is_deleted,
    account: transaction.account.value,
    project: transaction.project ? transaction.project.value : null,
    cost_center: transaction.cost_center ? transaction.cost_center.value : null,
    amount: transaction.credit ? -transaction.credit : transaction.debit,
    title: transaction.title,
    id: transaction.id || undefined,
    sie_file_name: transaction.sie_file_name,
    periodisation_config: !transaction.periodisation_config
      ? null
      : {
          ...transaction.periodisation_config,
          date_start: formatISO(transaction.periodisation_config.date_start, {
            representation: "date",
          }),
          pl_account_id: transaction.periodisation_config.pl_account.value,
          status:
            transaction.periodisation_config.status === "creating"
              ? "not_finished"
              : transaction.periodisation_config.status,
        },
  };
};

function VerificationForm({
  isDeleted,
  isConsult,
  accountOptions,
  accountById,
  perCostAccountOptions,
  projectOptions,
  centerOptions,
  verificationId,
  verificationSeries,
  companyId,
  hasPaymentSpecification,
}) {
  const { t } = useTranslation("ver");
  const bookingDateDisabled = verificationSeries === "C" || verificationSeries === "S";
  const [globalProject, setGlobalProject] = useState(undefined);
  const [globalCC, setGlobalCC] = useState(undefined);

  return (
    <>
      <Row>
        <Col md={3}>
          <FormGroup.BookingDatePicker
            label={t("common:dates.bookingDate")}
            name="booking_date"
            popperClassName="popper-in-modal"
            isClearable={false}
            readOnly={bookingDateDisabled}
            required
          />
        </Col>
        <Col md={6}>
          <FormGroup.Input label={`${t("common:verShort")} ${t("common:name")}`} name="title" required />
        </Col>
        {verificationId && !isDeleted && (
          <Col md={3}>
            <HistoryButton apiResource={verAPI.history} apiParams={[companyId, verificationId]} />
            {hasPaymentSpecification && (
              <PaymentSpecificationPdfButton verification={{ id: verificationId }} companyId={companyId} />
            )}
          </Col>
        )}
      </Row>
      <GlobalPRCC
        companyId={companyId}
        projectOptions={projectOptions}
        centerOptions={centerOptions}
        setGlobalProject={setGlobalProject}
        setGlobalCC={setGlobalCC}
        globalProject={globalProject}
        globalCC={globalCC}
      />
      <FieldArray
        name="transactions"
        render={(arrayHelper) => (
          <TransactionsForm
            isDeleted={isDeleted}
            isConsult={isConsult}
            arrayHelper={arrayHelper}
            accountOptions={accountOptions}
            accountById={accountById}
            perCostAccountOptions={perCostAccountOptions}
            projectOptions={projectOptions}
            centerOptions={centerOptions}
            verificationId={verificationId}
            verificationSeries={verificationSeries}
            globalProject={globalProject}
            globalCC={globalCC}
            companyId={companyId}
          />
        )}
      />
    </>
  );
}

function GlobalPRCC({
  companyId,
  projectOptions,
  centerOptions,
  setGlobalProject,
  setGlobalCC,
  globalProject,
  globalCC,
}) {
  const { t } = useTranslation("common");
  const newProjectModal = useModal();
  const newCCModal = useModal();
  const {
    values: { transactions },
    setFieldValue,
  } = useFormikContext();
  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenters.length !== 0;
  const { updateCompanyProjectsOptions, updateCompanyCostCentersOptions } = useCompanyDispatch();
  const onProjectSelect = (selected) => {
    if (selected === null || !selected.isDisabled) {
      setGlobalProject(selected);
      setFieldValue(
        "transactions",
        transactions.map((tran) => ({
          ...tran,
          project: !!tran.account && !tran.is_deleted ? selected : null,
        }))
      );
    }
  };
  const onCostCenterSelect = (selected) => {
    if (selected === null || !selected.isDisabled) {
      setGlobalCC(selected);
      setFieldValue(
        "transactions",
        transactions.map((tran) => ({
          ...tran,
          cost_center: !!tran.account && !tran.is_deleted ? selected : null,
        }))
      );
    }
  };
  const onNewProjectSuccess = (newProject) => {
    const newProjectOption = {
      ...newProject,
      value: newProject.id,
      label: `${newProject.serial} ${newProject.name}`,
      isDisabled: !newProject.is_active,
    };
    const newProjectOptions = [...projectOptions, newProjectOption];
    newProjectOptions.sort((a, b) => a.serial - b.serial);
    updateCompanyProjectsOptions(newProjectOptions);
    onProjectSelect(newProjectOption);
  };
  const onNewCCSuccess = (newCC) => {
    const newCCOption = {
      ...newCC,
      value: newCC.id,
      label: `${newCC.serial} ${newCC.name}`,
      isDisabled: !newCC.is_active,
    };
    const newCCOptions = [...centerOptions, newCCOption];
    newCCOptions.sort((a, b) => a.serial.localeCompare(b.serial));
    updateCompanyCostCentersOptions(newCCOptions);
    onCostCenterSelect(newCCOption);
  };
  if (!showProjects && !showCostCenter) {
    return null;
  }
  return (
    <>
      <Row>
        {showProjects && (
          <Col md={3}>
            <SelectSimpleFilter
              creatable
              options={activeProjects}
              label={t("project")}
              isClearable
              name="project"
              onChange={onProjectSelect}
              formatCreateLabel={() => formatCreateLabel(t, "project")}
              onCreateOption={(newInput) => newProjectModal.open({ initialSerialOrName: newInput })}
              value={globalProject}
            />
          </Col>
        )}
        {showCostCenter && (
          <Col md={3}>
            <SelectSimpleFilter
              creatable
              options={activeCenters}
              label={t("costCenter")}
              isClearable
              name="costCenter"
              onChange={onCostCenterSelect}
              formatCreateLabel={() => formatCreateLabel(t)}
              onCreateOption={(newInput) => newCCModal.open({ initialSerialOrName: newInput })}
              value={globalCC}
            />
          </Col>
        )}
      </Row>
      {newProjectModal.show && (
        <ProjectAddModal
          companyId={companyId}
          onSuccess={(project) => onNewProjectSuccess(project)}
          handleClose={newProjectModal.close}
          initialSerialOrName={newProjectModal.data.initialSerialOrName}
        />
      )}
      {newCCModal.show && (
        <CCAddModal
          companyId={companyId}
          onSuccess={(cc) => onNewCCSuccess(cc)}
          handleClose={newCCModal.close}
          initialSerialOrName={newCCModal.data.initialSerialOrName}
        />
      )}
    </>
  );
}

function TransactionsForm({
  isDeleted,
  isConsult,
  accountOptions,
  accountById,
  perCostAccountOptions,
  projectOptions,
  centerOptions,
  arrayHelper,
  verificationId,
  verificationSeries,
  globalProject,
  globalCC,
  companyId,
}) {
  const { t } = useTranslation("ver");
  const { values, errors, setValues, setFieldValue } = useFormikContext();
  const [showDeleted, setShowDeleted] = useState(isDeleted);
  const { fetchBalance, updateBalances } = useContext(BalanceStateContext);
  const { transactions } = values;
  const debounceRef = useRef(null);
  const activeTrans = transactions.filter((tr) => !tr.is_deleted);
  const debit = roundMoney(collectionSum(activeTrans, "debit"));
  const credit = roundMoney(collectionSum(activeTrans, "credit"));
  const diff = roundMoney(debit - credit);
  const isManualVerification = verificationSeries === "M";
  const verPreviewModal = useModal();
  const verPreviewModalForPeriodisationActions = useModal();
  const verModalForPeriodisationActions = useModal();
  const [fieldIndexesWithPerRowShowed, setFieldIndexesWithPerRowShowed] = useState([]);
  const [existPerConfigStatuses, setExistPerConfigStatuses] = useState(
    transactions
      .filter((tran) => !!tran.periodisation_config?.id)
      .reduce((result, filter) => {
        result[filter.periodisation_config.id] = filter.periodisation_config.status;
        return result;
      }, {})
  );
  const hasPermForInventoryRead = HasPermCode(codesForInventoryRegister.enabled);
  const newProjectModal = useModal();
  const newCCModal = useModal();

  const { updateCompanyProjectsOptions, updateCompanyCostCentersOptions } = useCompanyDispatch();

  function undoRemoveTransaction({ transaction, index }) {
    arrayHelper.replace(index, { ...transaction, is_deleted: false });
    updateBalances(
      transactions.map((tran) => {
        if (tran.id !== transaction.id) {
          return tran;
        }
        return { ...tran, is_deleted: false };
      })
    );
  }

  function addTransaction(initialData) {
    const item = {
      ...emptyTransaction,
      uid: _.uniqueId("tran"),
      ...initialData,
    };
    arrayHelper.push(item);
    return item;
  }

  function onAccountSelected(account, index) {
    // try to have only 1 extra empty
    if (!periodisationAccounts.includes(account)) {
      setFieldValue(`transactions[${index}].periodisation_config`, null);
    }
    if (isConsult) {
      fetchBalance(account.value);
    }
    const lastIndex = transactions.length - 1;
    if (lastIndex === index) {
      if (!transactions[index].project) {
        setFieldValue(`transactions[${index}].project`, globalProject);
      }
      if (!transactions[index].cost_center) {
        setFieldValue(`transactions[${index}].cost_center`, globalCC);
      }
    }
    if (!transactions[index].debit && !transactions[index].credit && transactions[transactions.length - 1].account) {
      addTransaction();
    } else if (lastIndex === index) {
      addTransaction();
    }
  }

  const onAmountChange = (account) => {
    if (account) {
      updateBalances(transactions);
    }
  };
  const creditOrDebitBlur = (event, index) => {
    if (event.key !== "Tab" && event.key !== "Enter") {
      return;
    }
    const transaction = transactions[index];
    if (diff > 0 && !transaction.debit && !transaction.credit) {
      transaction.credit = diff;
    } else if (diff < 0 && !transaction.debit && !transaction.credit) {
      transaction.debit = Math.abs(diff);
    }
    const newTransactions = appendAutoAccounts(transaction, index, transactions, accountById);
    newTransactions[index] = transaction;
    setValues({ ...values, transactions: newTransactions }, false);
  };

  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0 || transactions.filter((tr) => tr.project).length !== 0;
  const showCostCenter = activeCenters.length !== 0 || transactions.filter((tr) => tr.cost_center).length !== 0;

  function onClickPerTd(field, index) {
    if (verificationSeries === "PER") {
      return;
    }
    if (field.account.number === 1790) {
      if (field.id) {
        return;
      }
      const trans_account_field = `transactions[${index}].account`;
      setFieldValue(trans_account_field, accountById[1791]);
      onAccountSelected(accountById[1791], index);
      toast.warning(t("warning.perAccountChanged", { accountNumber: 1791 }));
    }
    if (field.account.number === 2990) {
      if (field.id) {
        return;
      }
      const trans_account_field = `transactions[${index}].account`;
      setFieldValue(trans_account_field, accountById[2991]);
      onAccountSelected(accountById[2991], index);
      toast.warning(t("warning.perAccountChanged", { accountNumber: 2991 }));
    }
    if (!field.periodisation_config) {
      setFieldIndexesWithPerRowShowed((f) => [...f, index]); // here you add the index
      setFieldValue(`transactions[${index}].periodisation_config`, { status: "creating", enabled: true });
    } else if (field.periodisation_config?.status === "creating") {
      setFieldIndexesWithPerRowShowed((f) => f.filter((i) => i !== index)); // here you remove the index
      setFieldValue(`transactions[${index}].periodisation_config`, null);
    }
  }

  function showHidePerRow(field, index) {
    if (field.periodisation_config?.enabled === true) {
      if (fieldIndexesWithPerRowShowed.includes(index)) {
        setFieldIndexesWithPerRowShowed((f) => f.filter((i) => i !== index)); // here you remove the index
      } else {
        setFieldIndexesWithPerRowShowed((f) => [...f, index]); // here you add the index
      }
    }
  }

  function hidePerRow(index) {
    if (fieldIndexesWithPerRowShowed.includes(index)) {
      setFieldIndexesWithPerRowShowed((f) => f.filter((i) => i !== index)); // here you remove the index
    }
  }

  function removeAllTransactions() {
    const newTransactions = [];
    for (let i = 0; i < transactions.length; i++) {
      if (transactions[i].id) {
        hidePerRow(i);
        newTransactions.push({
          ...transactions[i],
          is_deleted: true,
          periodisation_config: transactions[i].periodisation_config,
        });
      }
    }
    setValues({ ...values, transactions: [...newTransactions, { ...emptyTransaction, uid: _.uniqueId("tran") }] });
    updateBalances(newTransactions);
  }

  const removeTransaction = async ({ transaction, index }) => {
    if (
      transaction.periodisation_config?.id &&
      !["undone", "stopped_before_execution"].includes(existPerConfigStatuses[transaction.periodisation_config.id])
    ) {
      toast.error(t("error.undoPerBeforeTransDelete"));
      return null;
    }
    if (
      transaction.periodisation_config?.id &&
      existPerConfigStatuses[transaction.periodisation_config.id] === "undone"
    ) {
      const answer = await confirmExecute(t("ver:warning.onTranRemoveWithUndonePeriodisation"));
      if (!answer) {
        return null;
      }
    }
    if (!transaction.id) {
      arrayHelper.remove(index);
    } else {
      arrayHelper.replace(index, { ...transaction, is_deleted: true });
    }
    hidePerRow(index);
    updateBalances(
      transactions.map((tran) => {
        if (tran.id !== transaction.id) {
          return tran;
        }
        return { ...tran, is_deleted: true };
      })
    );
    return null;
  };

  function tdForPerColumn(field, index) {
    if (
      field.account &&
      periodisationToogleAccounts.includes(field.account.id) &&
      verificationSeries !== "PER" &&
      (!field.periodisation_config || field.periodisation_config.status === "creating")
    ) {
      if (field.id && !periodisationAccounts.includes(field.account.id)) {
        return <td />;
      }
      return (
        <td className="text-center periodisation-toogle" onClick={() => onClickPerTd(field, index)}>
          <i className={field.periodisation_config ? "fa fa-toggle-on" : "fa fa-toggle-off"} />
        </td>
      );
    }
    if (field.periodisation_config && field.periodisation_config.id) {
      return (
        <td className="text-center text-primary" onClick={() => showHidePerRow(field, index)}>
          <i className={fieldIndexesWithPerRowShowed.includes(index) ? "fa fa-angle-up" : "fa fa-angle-down"} />
        </td>
      );
    }
    return <td />;
  }

  function tdForDeleteColumn(field, index) {
    if (field.is_deleted) {
      return (
        <td className="text-center">
          <TooltipActionButton
            size="xs"
            icon="fas fa-undo-alt"
            variant="red"
            text={t("common:actions.undoRemove")}
            disabled={isDeleted}
            onClick={() =>
              undoRemoveTransaction({
                transaction: field,
                index,
                arrayHelper,
              })
            }
          />
        </td>
      );
    }
    return (
      <td className="text-center">
        <TooltipActionButton
          size="xs"
          icon="fas fa-trash"
          variant="red"
          text={t("common:actions.remove")}
          disabled={isDeleted}
          onClick={() =>
            removeTransaction({
              transaction: field,
              index,
              arrayHelper,
            })
          }
        />
      </td>
    );
  }

  const onClickPerStatusChange = async (field, index, action) => {
    verModalForPeriodisationActions.open({ periodisationConfigId: field.periodisation_config.id, action });
  };
  const onPerStatusChangedSuccess = (newData) => {
    existPerConfigStatuses[newData.id] = newData.status;
    setExistPerConfigStatuses({ ...existPerConfigStatuses });
    toast.success(t("msg:updated"));
    verModalForPeriodisationActions.close();
  };
  const onPeriodisationVerPreviewClick = (field) => {
    const formatedTransaction = getTransactionPayload(field);
    return verAPI
      .periodisationVerificationsPreview(companyId, {
        ...formatedTransaction.periodisation_config,
        per_amount: formatedTransaction.amount,
        per_account: formatedTransaction.account,
        cost_center: formatedTransaction.cost_center,
        project: formatedTransaction.project,
        booking_date: formatISO(values.booking_date, { representation: "date" }),
        series: values.series,
        series_number: values.series_number,
        title: values.title,
      })
      .then((response) => {
        verPreviewModal.open({ verifications: response.data });
      })
      .catch((error) => {
        handleActionErrors(error);
      });
  };
  const onPeriodisationActionsVerPreviewClick = (field, undoOnly = false) => {
    verPreviewModalForPeriodisationActions.open({ periodisationConfigId: field.periodisation_config.id, undoOnly });
  };

  function trForPeriodisationConfig(field, index) {
    if (!field.account) {
      return null;
    }
    if (!field.periodisation_config) {
      return null;
    }
    if (!fieldIndexesWithPerRowShowed.includes(index)) {
      return null;
    }
    const isPerPreviewDisabled = () => {
      return !(
        !!field.periodisation_config.months &&
        !!field.periodisation_config.date_start &&
        !!field.periodisation_config.pl_account &&
        (!!field.debit || !!field.credit)
      );
    };
    return (
      <tr>
        <td colSpan={12}>
          <table className="periodisation-row" width="100%">
            <tbody>
              <tr>
                <td className="label">{t("ver:perStartOn")}</td>
                <TableGroup.DatePicker
                  name={`transactions[${index}].periodisation_config.date_start`}
                  required
                  popperClassName="popper-in-modal"
                  tdProps={{ style: { width: 130, maxWidth: 130 } }}
                  disabled={field.periodisation_config?.id}
                />
                <td className="label">{t("common:months")}</td>
                <TableGroup.NumberInput
                  name={`transactions[${index}].periodisation_config.months`}
                  required
                  tdProps={{ style: { width: 95, maxWidth: 95 } }}
                  disabled={field.periodisation_config?.id}
                  min={1}
                  max={60}
                />
                <td className="label">{t("ver:plAccount")}</td>
                <TableGroup.SimpleSelect
                  options={perCostAccountOptions}
                  name={`transactions[${index}].periodisation_config.pl_account`}
                  tdProps={{ style: { width: 160, maxWidth: 160 } }}
                  isDisabled={field.periodisation_config?.id}
                />
                {!(field.periodisation_config?.status === "creating") && (
                  <>
                    <td className="label">{t("common:statuses.status")}</td>
                    <td>{t(`common:${existPerConfigStatuses[field.periodisation_config.id]}`)}</td>
                  </>
                )}
                <td />
                <td />
                <td />
                {!field.periodisation_config?.id && (
                  <td className="text-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={
                        <Tooltip>
                          {isPerPreviewDisabled() ? t("ver:fillMandatoryForPerPreview") : t("ver:previewVer")}
                        </Tooltip>
                      }
                    >
                      <Button
                        variant="toggle"
                        className="preview-btn"
                        disabled={isPerPreviewDisabled()}
                        onClick={() => onPeriodisationVerPreviewClick(field)}
                      >
                        <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                      </Button>
                    </OverlayTrigger>
                  </td>
                )}
                {field.periodisation_config?.id &&
                  existPerConfigStatuses[field.periodisation_config.id] === "not_finished" && (
                    <td>
                      <ButtonGroup className="periodisation-actions-group-buttons">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>{t("common:actions.previewActions")}.</Tooltip>}
                        >
                          <Button
                            type="button"
                            variant="toggle"
                            onClick={() => onPeriodisationActionsVerPreviewClick(field)}
                          >
                            <i className="mdi mdi-file-search-outline" />
                          </Button>
                        </OverlayTrigger>
                        <span className="line" />
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("common:actions.end")}.</Tooltip>}>
                          <Button
                            type="button"
                            variant="toggle"
                            onClick={() => onClickPerStatusChange(field, index, "end")}
                          >
                            <i className="fe-skip-forward" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("common:actions.stop")}.</Tooltip>}>
                          <Button
                            type="button"
                            variant="toggle"
                            onClick={() => onClickPerStatusChange(field, index, "stop")}
                          >
                            <i className="fe-square" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("common:actions.undo")}.</Tooltip>}>
                          <Button
                            type="button"
                            variant="toggle"
                            onClick={() => onClickPerStatusChange(field, index, "undo")}
                          >
                            <i className="fe-rotate-ccw" />
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </td>
                  )}
                {field.periodisation_config?.id &&
                  ["stopped", "ended", "finished"].includes(existPerConfigStatuses[field.periodisation_config.id]) && (
                    <td>
                      <ButtonGroup className="periodisation-actions-group-buttons">
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>{t("common:actions.previewActions")}.</Tooltip>}
                        >
                          <Button
                            type="button"
                            variant="toggle"
                            onClick={() => onPeriodisationActionsVerPreviewClick(field, true)}
                          >
                            <i className="mdi mdi-file-search-outline" />
                          </Button>
                        </OverlayTrigger>
                        <span className="line" />
                        <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("common:actions.undo")}</Tooltip>}>
                          <Button
                            type="button"
                            variant="toggle"
                            onClick={() => onClickPerStatusChange(field, index, "undo")}
                          >
                            <i className="fe-rotate-ccw" />
                          </Button>
                        </OverlayTrigger>
                      </ButtonGroup>
                    </td>
                  )}
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    );
  }

  function cleanInputAfterTyping(name, event, index) {
    const { value } = event.target;
    if (debounceRef.current) {
      debounceRef.current.cancel();
    }
    if (value) {
      const debounced = _.debounce(() => {
        setFieldValue(`transactions[${index}].${name}`, "");
      }, 800);
      debounced();
      debounceRef.current = debounced;
    }
  }

  const onNewProjectSuccess = (newProject, field_name) => {
    const newProjectOption = {
      ...newProject,
      value: newProject.id,
      label: `${newProject.serial} ${newProject.name}`,
      isDisabled: !newProject.is_active,
    };
    const newProjectOptions = [...projectOptions, newProjectOption];
    newProjectOptions.sort((a, b) => a.serial - b.serial);
    updateCompanyProjectsOptions(newProjectOptions);
    if (!newProjectOption.isDisabled) {
      setFieldValue(field_name, newProjectOption);
    }
  };
  const onNewCCSuccess = (newCC, field_name) => {
    const newCCOption = {
      ...newCC,
      value: newCC.id,
      label: `${newCC.serial} ${newCC.name}`,
      isDisabled: !newCC.is_active,
    };
    const newCCOptions = [...centerOptions, newCCOption];
    newCCOptions.sort((a, b) => a.serial.localeCompare(b.serial));
    updateCompanyCostCentersOptions(newCCOptions);
    if (!newCCOption.isDisabled) {
      setFieldValue(field_name, newCCOption);
    }
  };
  return (
    <>
      <label>{t("transactions")}</label>
      <Button
        variant="primary"
        className="addTransBtn"
        size="xs"
        tabIndex="-1"
        title={t("addTransaction")}
        onClick={() => addTransaction()}
      >
        <i className="fas fa-plus" />
      </Button>
      <Table bordered responsive size="sm" className="transactions">
        <thead>
          <tr>
            <th style={{ minWidth: 100 }}>{t("common:account")}</th>
            <th>{t("common:description")}</th>
            {showProjects && <th>{t("common:project")}</th>}
            {showCostCenter && <th>{t("common:costCenter")}</th>}
            <th className="text-right" style={{ width: 100 }}>
              {t("common:money.debit")}
            </th>
            <th className="text-right" style={{ width: 100 }}>
              {t("common:money.credit")}
            </th>
            {isConsult && (
              <th className="text-right" style={{ width: 100 }}>
                {t("common:money.balance")}
              </th>
            )}
            <th className="text-right">PER</th>
            <th style={{ width: 50 }}>
              <TooltipActionButton
                size="xs"
                icon="fas fa-trash"
                variant="red"
                text={t("common:actions.remove")}
                onClick={() => removeAllTransactions(arrayHelper)}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((field, index) => (
            <React.Fragment key={field.id || field.uid}>
              <tr
                className={cx({
                  removed: field.is_deleted,
                  "d-none": field.is_deleted && !showDeleted,
                })}
              >
                <TableGroup.SimpleSelect
                  options={accountOptions}
                  name={`transactions[${index}].account`}
                  isDisabled={field.is_deleted || !!field.id || !!field.autoParent}
                  onChange={(selected) => onAccountSelected(selected, index, arrayHelper)}
                  filterOptionStartsWith
                />
                <TableGroup.Input name={`transactions[${index}].title`} disabled={field.is_deleted} />
                {showProjects && (
                  <TableGroup.SimpleSelect
                    creatable
                    options={activeProjects}
                    formatCreateLabel={() => formatCreateLabel(t, "project")}
                    isClearable
                    name={`transactions[${index}].project`}
                    isDisabled={field.is_deleted || field.periodisation_config?.id}
                    onCreateOption={(newInput) =>
                      newProjectModal.open({
                        field_name: `transactions[${index}].project`,
                        initialSerialOrName: newInput,
                      })
                    }
                  />
                )}
                {showCostCenter && (
                  <TableGroup.SimpleSelect
                    creatable
                    options={activeCenters}
                    isClearable
                    name={`transactions[${index}].cost_center`}
                    isDisabled={field.is_deleted || field.periodisation_config?.id}
                    onCreateOption={(newInput) =>
                      newCCModal.open({
                        field_name: `transactions[${index}].cost_center`,
                        initialSerialOrName: newInput,
                      })
                    }
                    formatCreateLabel={() => formatCreateLabel(t)}
                  />
                )}
                {isManualVerification ? (
                  <>
                    <TableGroup.MoneyCalculationTableInput
                      name={`transactions[${index}].debit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("credit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                    />
                    <TableGroup.MoneyCalculationTableInput
                      name={`transactions[${index}].credit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("debit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                      tabIndex="-1"
                    />
                  </>
                ) : (
                  <>
                    <TableGroup.MoneyInput
                      name={`transactions[${index}].debit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("credit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                    />
                    <TableGroup.MoneyInput
                      name={`transactions[${index}].credit`}
                      disabled={field.is_deleted || !!field.id || !!field.autoParent}
                      onChange={(event) => {
                        cleanInputAfterTyping("debit", event, index);
                      }}
                      onKeyDown={(event) => {
                        creditOrDebitBlur(event, index);
                      }}
                      onBlur={(event) => {
                        onAmountChange(field.account);
                      }}
                      tabIndex="-1"
                    />
                  </>
                )}
                {isConsult && (
                  <td className="text-right pr-1 disabled">
                    {field.account && !field.is_deleted && <TransactionBalance account={field.account.number} />}
                  </td>
                )}
                {}
                {tdForPerColumn(field, index)}
                {tdForDeleteColumn(field, index)}
              </tr>
              {trForPeriodisationConfig(field, index)}
              <TableGroup.RowErrors errors={errors.transactions && errors.transactions[index]} />
            </React.Fragment>
          ))}
        </tbody>
        <tfoot>
          <tr className="error">
            <td
              colSpan={2 + showCostCenter + showProjects}
              className={cx({
                "has-error": _.isString(errors.transactionsArray),
              })}
            >
              {_.isString(errors.transactionsArray) && errors.transactionsArray}
            </td>
            <td className="text-right">{formatMoney(debit)}</td>
            <td className="text-right">{formatMoney(credit)}</td>
            <td />
            <td />
          </tr>
          {errors.transactions && typeof errors.transactions === "string" && (
            <tr>
              <td colSpan={10}>
                <Alert variant="danger">{errors.transactions}</Alert>
              </td>
            </tr>
          )}
          {hasPermForInventoryRead &&
            isAssetToAutoAddBasedOnTransactions(values.transactions.filter((tran) => !tran.id)) && (
              <tr>
                <td colSpan={10}>
                  <Alert variant="warning">{t("assetRedirectionInfo")}</Alert>
                </td>
              </tr>
            )}
          {isDepreciationTransactionIn(values.transactions.filter((tran) => !tran.id)) && (
            <tr>
              <td colSpan={10}>
                <Alert variant="warning">{t("deprecationAccountUsedInsteadInventory")}</Alert>
              </td>
            </tr>
          )}
          {verificationSeries === "AVS" && (
            <tr>
              <td colSpan={10}>
                <Alert variant="warning">{t("changesNotAppliesToInventoryRegister")}</Alert>
              </td>
            </tr>
          )}
          <tr className="summary">
            <td colSpan={2 + showCostCenter + showProjects}>
              <CheckboxFilter
                label={t("showDeleted")}
                name="showDeleted"
                defaultChecked={showDeleted}
                onFilter={() => setShowDeleted(!showDeleted)}
              />
            </td>
            <td className="text-right">{t("common:money.deviation")}</td>
            <td>{formatMoney(diff)}</td>
          </tr>
        </tfoot>
      </Table>
      {newProjectModal.show && (
        <ProjectAddModal
          companyId={companyId}
          onSuccess={(project) => onNewProjectSuccess(project, newProjectModal.data.field_name)}
          handleClose={newProjectModal.close}
          initialSerialOrName={newProjectModal.data.initialSerialOrName}
        />
      )}
      {newCCModal.show && (
        <CCAddModal
          companyId={companyId}
          onSuccess={(cc) => onNewCCSuccess(cc, newCCModal.data.field_name)}
          handleClose={newCCModal.close}
          initialSerialOrName={newCCModal.data.initialSerialOrName}
        />
      )}
      {verPreviewModal.show && (
        <BaseVerificationsPreviewModal
          handleClose={verPreviewModal.close}
          verifications={verPreviewModal.data.verifications}
          isLoading={false}
        />
      )}
      {verPreviewModalForPeriodisationActions.show && (
        <PeriodisationEndUndoStopPreviewsModal
          perConfigId={verPreviewModalForPeriodisationActions.data.periodisationConfigId}
          handleClose={verPreviewModalForPeriodisationActions.close}
          undoOnly={verPreviewModalForPeriodisationActions.data.undoOnly}
        />
      )}
      {verModalForPeriodisationActions.show && (
        <PeriodisationActionModal
          perConfigId={verModalForPeriodisationActions.data.periodisationConfigId}
          onCancel={verModalForPeriodisationActions.close}
          onSuccess={onPerStatusChangedSuccess}
          action={verModalForPeriodisationActions.data.action}
        />
      )}
    </>
  );
}

export default VerificationForm;
export { getTransactionPayload };
