import React, { useContext } from "react";
import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";

import * as options from "api/options";
import * as companyAPIOld from "api/company";
import * as companyAPI from "api2/companies";
import { AllError, FormGroup } from "components/formik";
import { SubmitButton } from "components/ui/buttons";
import { CompanySettingStateContext } from "state/providers/CompanySettingProvider";
import ConditionalOverlayTrigger from "components/ui/OverlayTriggers/ConditionalOverlayTrigger";

function AdminForm({ company, ttOnly, bgcAccounts }) {
  const { t } = useTranslation("company");
  const companyPaymentMethods = options.companyPaymentMethods.asList();
  const companyTypes = options.companyTypes.asList();
  const companyStatuses = options.companyStatuses.asList();
  const paymentProviders = options.paymentProviders.asList();
  const { info, updateInfo } = useContext(CompanySettingStateContext);
  const [billecta, setBillecta] = React.useState({
    loading: false,
    error: null,
  });
  const [einvoice, setEinvoice] = React.useState({
    loading: false,
    error: null,
  });
  const bgcAccount = company.booking_bank_account && bgcAccounts.byId[company.booking_bank_account];

  const formikProps = {
    initialValues: {
      ...company,
      consult: company.consult && {
        value: company.consult,
        label: company.consult_name,
      },
      booking_bank_account: bgcAccount || bgcAccounts.asList[0],
      company_type: options.companyTypes.getOption(company.company_type) || companyTypes[0],
      status: options.companyStatuses.getOption(company.status) || companyStatuses[0],
      payment_method: options.companyPaymentMethods.getOption(company.payment_method) || companyPaymentMethods[0],
      payment_provider: options.paymentProviders.getOption(company.payment_provider),
      bank_name:
        company.bank_name && company.bank_name === "other"
          ? company.other_bank_name || ""
          : options.bankChoices.getLabel(company.bank_name) || "",
      customer_invoice_enabled: company.customer_invoice_enabled || company.billecta_enabled,
    },
    validationSchema: yup.object().shape({
      ocr_tax: yup.string(),
      bankgiro_no: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when(["payment_method", "payment_provider"], ([paymentMethod, paymentProvider], schema) => {
            const isBankgiro = paymentMethod && paymentMethod.value === "bankgiro";
            const isBgc = paymentProvider && paymentProvider.value === "bgc";
            if (isBankgiro || isBgc) {
              return schema.required();
            }
            return schema.notRequired();
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      plusgiro_no: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "plusgiro",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bic: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      iban: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      clearing_number: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      bank_account_number: yup.string().when("customer_invoice_enabled", {
        is: true,
        then: () =>
          yup.string().when("payment_method", {
            is: (method) => method && method.value === "bankaccount",
            then: () => yup.string().required(),
            otherwise: () => yup.string().notRequired(),
          }),
        otherwise: () => yup.string().notRequired(),
      }),
      forward_invoice_email: yup
        .string()
        .email()
        .when("forward_emails", ([enabled], schema) => {
          return enabled ? schema.required() : schema;
        }),
      forward_receipt_email: yup
        .string()
        .email()
        .when("forward_emails", ([enabled], schema) => {
          return enabled ? schema.required() : schema;
        }),
      forward_archive_email: yup
        .string()
        .email()
        .when("forward_emails", ([enabled], schema) => {
          return enabled ? schema.required() : schema;
        }),
    }),
    onSubmit: async (values, { setErrors, resetForm, setFieldValue }) => {
      setBillecta({ loading: true, error: null });
      await companyAPI
        .advancedUpdate(company.id, {
          ...values,
          consult: values.consult && values.consult.value,
          booking_bank_account: values.booking_bank_account && values.booking_bank_account.value,
          company_type: values.company_type.value,
          status: values.status.value,
          payment_method: values.payment_method.value,
          payment_provider: values.payment_provider.value,
          iban: values.iban.split(" ").join(""),
          bank_id_token: "",
        })
        .then((response) => {
          toast.success(t("msg:saved"), { autoClose: 2000 });
          resetForm({ values });
          updateInfo({
            ...info,
            budget_enabled: response.data.budget_enabled,
            time_reporting_enabled: response.data.time_reporting_enabled,
          });
          if (response.data.billecta_enabled) {
            setFieldValue("billecta_enabled", true);
            setFieldValue("customer_invoice_enabled", true);
          }
        })
        .catch((error) => {
          const billectaMandatoryFields = [
            "address",
            "zip_code",
            "city",
            "phone",
            "contact_first_name",
            "contact_last_name",
            "contact_email",
            "contact_phone",
          ];
          const hasBillectaMandatoryFieldError = Object.keys(error.data).some((field) =>
            billectaMandatoryFields.includes(field)
          );
          if (hasBillectaMandatoryFieldError) {
            setBillecta({
              loading: false,
              error: error.status === 400 ? [t("msg:consultBillectaNotConfigured")] : "",
            });
          } else {
            toast.error(t("msg:fixErrors"));
            setErrors(error.data);
          }
        });
    },
  };

  async function registerBillecta(setFieldValue) {
    setBillecta({ loading: true, error: null });
    await companyAPIOld
      .registerBillecta(company.id)
      .then((response) => {
        toast.success(t("msg:saved"), { autoClose: 2000 });
        setFieldValue("billecta_enabled", true);
        setFieldValue("customer_invoice_enabled", true);
      })
      .catch((error) => {
        setBillecta({
          loading: false,
          error: error.status === 400 ? error.data : "",
        });
      });
  }

  async function registerEInvoice(setFieldValue) {
    setEinvoice({ loading: true, error: null });
    await companyAPI
      .eInvoiceRegister(company.id)
      .then(() => {
        toast.success(t("ci:einvoice.registrationWarn"), { autoClose: 4000 });
        setFieldValue("einvoice_enabled", true);
      })
      .catch((error) => {
        toast.error(t("msg:canNotExecuteAction"));
        setEinvoice({
          loading: false,
          error: error.status === 400 ? error.data : "",
        });
      });
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, touched, errors, setFieldValue }) => {
        const isRequiredAndEnabled = values.customer_invoice_enabled;
        return (
          <Form>
            <Card className="pt-2">
              <Card.Body>
                <Row>
                  <Col sm={6} xl={3}>
                    <ConditionalOverlayTrigger placement="bottom" useSpan>
                      <FormGroup.Input name="consult_name" label={t("common:consult")} disabled />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col sm={6} xl={3}>
                    <ConditionalOverlayTrigger placement="bottom" useSpan>
                      <FormGroup.Input name="company_type.label" label={t("companyType")} disabled />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.SimpleSelect
                      name="status"
                      label={t("common:statuses.status")}
                      options={companyStatuses}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup.Input
                      label={t("generalInformation")}
                      name="general_information"
                      as="textarea"
                      rows={3}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <FormGroup.MandrillReceiveEmailInput
                      allowedDomain={company.mandrill_receive_domain}
                      label={t("invoiceReceiveEmail")}
                      name="mandrill_email_pattern"
                      disabled={ttOnly}
                    />
                  </Col>
                </Row>
                {values.mandrill_email_pattern && (
                  <fieldset className="forward-emails">
                    <Row>
                      <Col lg={3}>
                        <FormGroup.LabeledCheckbox label={t("forwardEmails")} name="forward_emails" />
                      </Col>
                    </Row>
                    {values.forward_emails && (
                      <Row>
                        <Col lg={3}>
                          <FormGroup.Input name="forward_invoice_email" label={t("forwardInvoiceEmail")} required />
                        </Col>
                        <Col lg={3}>
                          <FormGroup.Input name="forward_receipt_email" label={t("forwardReceiptEmail")} required />
                        </Col>
                        <Col lg={3}>
                          <FormGroup.Input name="forward_archive_email" label={t("forwardArchiveEmail")} required />
                        </Col>
                      </Row>
                    )}
                  </fieldset>
                )}
                <Card.Title className="mt-3">{t("bankDetails")}</Card.Title>
                <Row>
                  <Col lg={3}>
                    <FormGroup.SimpleSelect
                      name="payment_method"
                      label={t("common:paymentMethod")}
                      options={companyPaymentMethods}
                    />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.MoneyInput name="overdraft_facility" label={t("common:overdraftFacility")} />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.SimpleSelect
                      required={isRequiredAndEnabled}
                      name="booking_bank_account"
                      label={`BGC ${t("common:account")}`}
                      options={bgcAccounts.asList}
                      isClearable={false}
                    />
                  </Col>
                </Row>
                <Row>
                  {(values.payment_method.value === "bankgiro" || values.payment_provider.value === "bgc") && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input
                        label={t("common:bankGiroNo")}
                        name="bankgiro_no"
                        required={isRequiredAndEnabled}
                      />
                    </Col>
                  )}
                  {values.payment_method.value === "plusgiro" && (
                    <Col sm={6} xl={3}>
                      <FormGroup.Input
                        label={t("common:plusGiroNo")}
                        name="plusgiro_no"
                        required={isRequiredAndEnabled}
                      />
                    </Col>
                  )}
                  <Col sm={6} xl={3}>
                    <ConditionalOverlayTrigger placement="bottom">
                      <FormGroup.Input label={t("bankName")} name="bank_name" disabled />
                    </ConditionalOverlayTrigger>
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input
                      label="BIC"
                      name="bic"
                      required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
                    />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input
                      label="IBAN"
                      name="iban"
                      required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
                    />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input
                      type="number"
                      name="clearing_number"
                      label={t("common:clearingNo")}
                      required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
                    />
                  </Col>
                  <Col sm={6} xl={3}>
                    <FormGroup.Input
                      name="bank_account_number"
                      label={t("common:bankAccountNo")}
                      required={values.payment_method.value === "bankaccount" && isRequiredAndEnabled}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={3}>
                    <FormGroup.SimpleSelect
                      name="payment_provider"
                      label={t("common:paymentProvider")}
                      options={paymentProviders}
                    />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    {values.payment_provider.value === "bgc" && (
                      <Alert variant="warning">{t("company:bgcPaymentProviderWarning")}</Alert>
                    )}
                  </Col>
                </Row>

                <Card.Title>{t("common:tax")}</Card.Title>
                <Row>
                  <Col lg={3}>
                    <FormGroup.Input
                      label={t("preliminaryTax")}
                      name="preliminary_tax"
                      type="number"
                      required={values.payment_method.value === "bankaccount"}
                    />
                  </Col>
                  <Col lg={3}>
                    <FormGroup.Input label={t("ocrTax")} name="ocr_tax" type="number" disabled />
                  </Col>
                </Row>
                {!ttOnly && (
                  <Row>
                    <Col xl={6}>
                      <Card.Title>{t("modules")}</Card.Title>
                      <FormGroup.Checkbox label={t("invoiceRotRut")} name="invoice_tax_deduction_enabled" />
                      {values.payment_provider.value === "bgc" && (
                        <FormGroup.Checkbox label={t("connectedBGC")} name="connected_to_bgc" />
                      )}
                      <FormGroup.Checkbox label={t("contractInvoicing")} name="contract_invoice_enabled" />
                      <FormGroup.Checkbox label={t("navigation:offerAndOrder")} name="offer_order_enabled" />
                      <FormGroup.Checkbox label={t("timeReporting")} name="time_reporting_enabled" />
                      <FormGroup.Checkbox label={t("cashflow")} name="cashflow_enabled" />
                      <FormGroup.Checkbox label={t("absenceReport")} name="absence_report_enabled" />
                      <FormGroup.Checkbox label={t("reports:budget")} name="budget_enabled" />
                      <FormGroup.Checkbox
                        label={t("customerInvoices")}
                        name="customer_invoice_enabled"
                        disabled={values.billecta_enabled}
                      />
                    </Col>
                    <Col xl={6}>
                      <Card.Title>{t("integrations")}</Card.Title>
                      <FormGroup.Checkbox label="Billecta" name="billecta_enabled" disabled />
                      <FormGroup.Checkbox
                        label={t("ciReceiveEmailOption")}
                        name="ci_open_email_enabled"
                        disabled={!values.billecta_enabled}
                        wrapperClass="ml-3"
                      />
                      <FormGroup.Checkbox label="E-Invoice" name="einvoice_enabled" disabled />
                      <FormGroup.Checkbox label="Skatteverket" name="skatteverket_enabled" disabled />
                    </Col>
                  </Row>
                )}
                {billecta.error && (
                  <Alert variant="danger" className="mt-2">
                    {Object.values(billecta.error).map((msg, i) => (
                      <p key={i} className="m-0 p-0">
                        {msg}
                      </p>
                    ))}
                  </Alert>
                )}
                {einvoice.error && (
                  <Alert variant="danger" className="mt-2">
                    {Object.values(einvoice.error).map((msg, i) => (
                      <p key={i} className="m-0 p-0">
                        {msg}
                      </p>
                    ))}
                  </Alert>
                )}
                <AllError errors={errors} />
              </Card.Body>
              <Card.Footer>
                <SubmitButton isSubmitting={isSubmitting} />
                {!ttOnly && (
                  <>
                    {!values.billecta_enabled && (
                      <Button
                        variant="toggle-grey"
                        disabled={isSubmitting || billecta.loading}
                        className="ml-3"
                        onClick={() => registerBillecta(setFieldValue)}
                      >
                        {t("actions.registerBillecta")}
                      </Button>
                    )}
                    {!values.einvoice_enabled && (
                      <Button
                        variant="toggle-grey"
                        disabled={isSubmitting || einvoice.loading}
                        className="ml-3"
                        onClick={() => registerEInvoice(setFieldValue)}
                      >
                        {t("actions.registerEInvoice")}
                      </Button>
                    )}
                  </>
                )}
              </Card.Footer>
            </Card>
          </Form>
        );
      }}
    </Formik>
  );
}

export default AdminForm;
