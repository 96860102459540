import React, { useCallback, useState } from "react";
import { Button, ButtonGroup, Card, Table } from "react-bootstrap";
import { useFormikContext } from "formik";

import { TooltipActionButton } from "components/ui/buttons";
import { TableGroup } from "components/formik";
import { useTranslation } from "react-i18next";
import {
  SpecificationFilesUploadPreviewModal,
  SpecificationVerificationFileModal,
} from "components/modals/SpecificationModals";
import useModal from "hooks/useModal";
import { parseDate } from "utils/date";
import FetchSpecificationButton from "../FetchSpecificationButton";

function SpecificationActions({ spec, index, companyId, onRemove, onUndoRemove, onFileUpload }) {
  const uploadModal = useModal();
  const previewModal = useModal();
  const { t } = useTranslation("common");

  const previewDocuments = () => {
    if (spec.verification) {
      previewModal.open({
        id: spec.verification,
        title: spec.verification_number,
      });
    } else {
      uploadModal.open({ specification: spec, index });
    }
  };

  const onUpload = (files) => {
    onFileUpload(index, files);
  };

  let Buttons;
  if (spec.is_deleted) {
    Buttons = (
      <TooltipActionButton
        variant="red"
        icon="fas fa-undo-alt"
        text={t("actions.undoRemove")}
        onClick={() => onUndoRemove(spec, index)}
      />
    );
  } else {
    Buttons = (
      <>
        <TooltipActionButton
          text={t("documents")}
          icon="fas fa-images"
          variant={spec.documents.length ? "primary" : "secondary"}
          onClick={previewDocuments}
        />
        <TooltipActionButton
          variant="red"
          icon="fas fa-trash"
          text={t("actions.remove")}
          onClick={() => onRemove(spec, index)}
        />
      </>
    );
  }
  return (
    <>
      {Buttons}
      {uploadModal.show && (
        <SpecificationFilesUploadPreviewModal
          max={3}
          specification={uploadModal.data.specification}
          uploadHandler={onUpload}
          companyId={companyId}
          handleClose={uploadModal.close}
        />
      )}
      {previewModal.show && (
        <SpecificationVerificationFileModal
          companyId={companyId}
          verification={previewModal.data}
          handleClose={previewModal.close}
        />
      )}
    </>
  );
}

function SpecificationRow({ spec, index, companyId, onRemove, onUndoRemove, onFileUpload, onRemoveFromSpec }) {
  return (
    <tr>
      <td className="text-center">{spec.verification_number}</td>
      <TableGroup.Input name={`specifications[${index}].title`} />
      <TableGroup.DatePicker name={`specifications[${index}].booking_date`} />
      <TableGroup.MoneyCalculationInput name={`specifications[${index}].amount`} />
      <td className="td-actions">
        <SpecificationActions
          spec={spec}
          index={index}
          companyId={companyId}
          onRemove={onRemove}
          onUndoRemove={onUndoRemove}
          onFileUpload={onFileUpload}
          onRemoveFromSpec={onRemoveFromSpec}
        />
      </td>
    </tr>
  );
}

function SpecificationTable({
  companyId,
  accountNumber,
  reconciliationDate,
  financialYear,
  handleAddEmptySpec,
  handleRemoveSpec,
  handleUndoRemoveSpec,
}) {
  const { t } = useTranslation("reports");
  const {
    values: { specifications },
    setFieldValue,
  } = useFormikContext();

  const [addedSpecs, setAddedSpecs] = useState([]);

  const onFileUpload = useCallback(
    (index, files) => {
      setFieldValue(`specifications[${index}].documents`, files);
    },
    [setFieldValue]
  );

  const onFetchSpecificationSuccess = (specification) => {
    setFieldValue(
      "specifications",
      [
        ...specifications,
        {
          documents: [],
          ...specification,
          booking_date: parseDate(specification.booking_date),
          key: `${specification.id}`,
          verification: null,
        },
      ],
      false
    );
  };

  const handleRemoveFromSpec = (index) => {
    setAddedSpecs(addedSpecs.filter((i) => i !== index));
  };

  const tableRows = specifications.map((spec, index) => (
    <SpecificationRow
      key={spec.key}
      spec={spec}
      companyId={companyId}
      onFileUpload={onFileUpload}
      index={index}
      onRemove={handleRemoveSpec}
      onUndoRemove={handleUndoRemoveSpec}
      onRemoveFromSpec={handleRemoveFromSpec}
    />
  ));

  return (
    <Card>
      <Card.Body className="pb-0 specifications">
        <Table bordered size="sm">
          <thead>
            <tr>
              <th colSpan={5} className="vertical-middle">
                {t("specifications")}
                <ButtonGroup className="float-right">
                  <FetchSpecificationButton
                    accountNumber={accountNumber}
                    reconciliationDate={reconciliationDate}
                    financialYear={financialYear}
                    onFetchSuccess={onFetchSpecificationSuccess}
                    companyId={companyId}
                  />
                  <Button variant="secondary" size="sm" onClick={handleAddEmptySpec}>
                    <i className="fas fa-plus" /> {t("actions.addSpec")}
                  </Button>
                </ButtonGroup>
              </th>
            </tr>
            <tr>
              <th>{t("common:verShort")}</th>
              <th>{t("common:description")}</th>
              <th>{t("common:dates.bookingDate")}</th>
              <th className="text-right">{t("common:money.amount")}</th>
              <th />
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export default SpecificationTable;
