import React, { useContext, useEffect, useState } from "react";
import { Button, ButtonGroup, Card, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { isWithinInterval } from "date-fns";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useNavigate, useOutletContext } from "react-router-dom";
import qs from "qs";
import cx from "classnames";

import { DateRangeFilters, MultiSelectSimpleFilter } from "components/filters";
import * as reportAPI from "api2/reports";
import TableLoader from "components/tables/btable/TableLoader";
import { FileUrlModal } from "components/modals";
import useModal from "hooks/useModal";
import { formatMoney } from "utils/money";
import { FiltersContext } from "state/providers/FiltersProvider";

import { formatDate, isSameDayOrAfter } from "utils/date";
import { truncateText } from "utils/text";
import { filterActiveCC, filterActiveProjects } from "utils/others";
import { useCompanyState } from "hooks/useCompany";
import ViewSwitcher from "../ViewSwitcher";
import CompareBudgetButton from "./CompareBudgetButton";
import BudgetMarginController from "./BudgetMarginController";

const initialReport = {
  months: [],
  categories: [],
  sums: {},
  ack: 0,
};

function ProfitLoss12ReportPage() {
  const { company, parentPath } = useOutletContext();
  const [compareBudget, setCompareBudget] = useState(false);
  const [budgetMargin, setBudgetMargin] = useState(company.budget_margin);
  const { t } = useTranslation("reports");
  const { filters, updateFilter } = useContext(FiltersContext);
  const plPDFModal = useModal();
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState(initialReport);

  useEffect(() => {
    const { start, end, fYear } = filters;
    const signal = axios.CancelToken.source();

    function getReport() {
      if (
        fYear &&
        start &&
        end &&
        isSameDayOrAfter(end, start) &&
        isWithinInterval(start, {
          start: fYear.date_start,
          end: fYear.date_end,
        }) &&
        isWithinInterval(filters.end, {
          start: fYear.date_start,
          end: fYear.date_end,
        })
      ) {
        setLoading(true);
        reportAPI
          .profitAndLoss12(
            company.id,
            {
              date_from: formatDate(start),
              date_to: formatDate(end),
              financial_year: fYear.id,
              costcenter_ids: filters.costcenter_ids || [],
              project_ids: filters.project_ids || [],
              with_budget: compareBudget,
            },
            {
              cancelToken: signal.token,
              paramsSerializer: (params) => {
                return qs.stringify(params, { indices: false });
              },
            }
          )
          .then((response) => {
            setReport(response.data);
            setLoading(false);
          })
          .catch((error) => {
            if (!axios.isCancel(error)) {
              setReport(initialReport);
              setLoading(false);
            }
          });
      } else {
        setReport(initialReport);
        setLoading(false);
      }
    }

    getReport();

    return () => {
      signal.cancel("aborted");
    };
  }, [company.id, filters, compareBudget]);

  const openProfitLoss12PDFModal = () => {
    const params = qs.stringify(
      {
        date_from: formatDate(filters.start),
        date_to: formatDate(filters.end),
        financial_year: filters.fYear.id,
        costcenter_ids: filters.costcenter_ids || [],
        project_ids: filters.project_ids || [],
        with_budget: compareBudget,
      },
      { indices: false }
    );
    let url = "";
    if (compareBudget) {
      url = `/reports/profit-loss-year-budget/pdf/?${params}`;
    } else {
      url = `/reports/profit-loss-year/pdf/?${params}`;
    }
    plPDFModal.open(url);
  };

  const onToggleCompareBudget = (value) => {
    if (!company.budget_enabled) {
      return;
    }
    setCompareBudget(value);
  };

  return (
    <>
      <Card>
        <Card.Body className="pb-0">
          <div className="d-flex justify-content-between">
            <div>
              <ViewSwitcher view="yearly" />
            </div>
            <div>
              <div className="form-group">
                <label className="text-right"> </label>
                <div>
                  <ButtonGroup>
                    <Button variant="secondary" onClick={openProfitLoss12PDFModal}>
                      <i className="fas fa-file-pdf" /> {t("reportPDF")}
                    </Button>
                  </ButtonGroup>
                </div>
              </div>
            </div>
          </div>
          <div className="table-filters-group">
            <section className="table-filters-left">
              <Filters
                filters={filters}
                setFilters={updateFilter}
                compareBudget={compareBudget}
                budgetMargin={budgetMargin}
                setBudgetMargin={setBudgetMargin}
              />
            </section>
            <section className="table-filters-right">
              {company.budget_enabled && (
                <div className="form-group">
                  <CompareBudgetButton onChange={onToggleCompareBudget} />
                </div>
              )}
            </section>
          </div>
        </Card.Body>
      </Card>
      <Card className="reports profit-loss">
        {loading && <TableLoader />}
        <Card.Body>
          <ReportTable
            report={report}
            filters={filters}
            parentPath={parentPath}
            compareBudget={compareBudget}
            budgetMargin={budgetMargin}
            budgetNominal={company.budget_nominal}
          />
        </Card.Body>
      </Card>
      {plPDFModal.show && (
        <FileUrlModal fileUrl={plPDFModal.data} companyId={company.id} handleClose={plPDFModal.close} />
      )}
    </>
  );
}

function Filters({ filters, setFilters, compareBudget, budgetMargin, setBudgetMargin }) {
  const { t } = useTranslation("reports");
  const {
    company: { id: companyId },
    costCenters: { asOptions: costOptions },
    projects: { asOptions: projectOptions },
  } = useCompanyState();

  function onPeriodChange(start, end) {
    setFilters({ ...filters, start, end });
  }

  const onCCChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };
  const onProjectChange = (newData) => {
    setFilters({ ...filters, ...newData });
  };
  const showProjects = projectOptions.filter(filterActiveProjects).length !== 0;
  const showCostCenter = costOptions.filter(filterActiveCC).length !== 0;
  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <DateRangeFilters
          label={t("common:dates.period")}
          dateStart={filters.start}
          dateEnd={filters.end}
          onChange={({ start, end }) => onPeriodChange(start, end)}
        />
        {showCostCenter && (
          <MultiSelectSimpleFilter
            label={t("common:costCenter")}
            defaultValue={filters.costcenter_ids}
            options={costOptions}
            isClearable={false}
            name="costcenter_ids"
            onFilter={onCCChange}
          />
        )}
        {showProjects && (
          <MultiSelectSimpleFilter
            label={t("common:project")}
            defaultValue={filters.project_ids}
            options={projectOptions}
            isClearable={false}
            name="project_ids"
            onFilter={onProjectChange}
          />
        )}
        {compareBudget && (
          <BudgetMarginController companyId={companyId} budgetMargin={budgetMargin} setBudgetMargin={setBudgetMargin} />
        )}
      </section>
    </div>
  );
}

function BudgetOverlay({ text, tooltipText }) {
  const { t } = useTranslation("reports");
  return (
    <OverlayTrigger
      placement="left"
      overlay={
        <Tooltip id="budget-tooltip">
          <div className="budget-tooltip__inner">
            {t("budget")}: {tooltipText}
          </div>
        </Tooltip>
      }
    >
      <div>{text}</div>
    </OverlayTrigger>
  );
}

function ReportTable({ report, filters, parentPath, compareBudget = false, budgetMargin, budgetNominal }) {
  const { t } = useTranslation("reports");
  const navigate = useNavigate();
  const BUDGET_MARGIN = budgetMargin / 100;

  function goToLedger(accountNumber) {
    const ledgerPath = parentPath.match(/(consult|reports)$/) ? "/ledger/" : "ledger/";

    navigate(
      `${parentPath}${ledgerPath}${accountNumber}/?${qs.stringify({
        fyear: filters.fYear ? filters.fYear.id : undefined,
      })}`
    );
  }

  function getBudgetClass(account, monthIndex) {
    if (!compareBudget) {
      return "";
    }
    const monthBudget = Object.values(account.budgets)[monthIndex];
    const monthSum = Object.values(account.sums)[monthIndex];
    if (Math.abs(monthSum - monthBudget) <= budgetNominal / 1000 / 12) {
      return "budget-ok";
    }
    if (account.number < 4000) {
      return ((1 - BUDGET_MARGIN) * monthBudget) / 1000 <= monthSum ? "budget-ok" : "budget-fail";
    }
    return ((1 + BUDGET_MARGIN) * monthBudget) / 1000 <= monthSum ? "budget-ok" : "budget-fail";
  }

  return (
    <Table bordered className={cx("sticky-header", compareBudget && "compare-budget")}>
      <thead>
        <tr>
          <th>Resultaträkning 12 månader (Ksek)</th>
          {report.months.map((month) => (
            <th key={month}>{month}</th>
          ))}
          <th>ACK</th>
        </tr>
      </thead>
      <tbody>
        {report.categories.map((category, ci) => (
          <React.Fragment key={`ci${ci}`}>
            {category.name && (
              <tr className="parent-category--name">
                <th colSpan={report.months.length + 2}>{category.name}</th>
              </tr>
            )}
            {category.child_categories.map((child, cci) => (
              <React.Fragment key={`cci${cci}`}>
                {child.extra ? (
                  <tr className="extra-category">
                    <th>{child.extra_name}</th>
                    {Object.values(child.sums).map((sum, i) => (
                      <th key={`tool-sum${i}`}>{formatMoney(sum, 1)}</th>
                    ))}
                    <th>{formatMoney(child.ack, 1)}</th>
                  </tr>
                ) : (
                  <>
                    <tr className="child-category--name">
                      <th colSpan={report.months.length + 2}>{child.name}</th>
                    </tr>
                    {child.accounts &&
                      child.accounts.map((account, ai) => (
                        <tr key={`ai${ai}`} className="account">
                          <td>
                            <Button className="m-0 p-0" variant="link" onClick={() => goToLedger(account.number)}>
                              {truncateText(`${account.number} - ${account.name}`, 40)}
                            </Button>
                          </td>
                          {Object.values(account.sums).map((sum, i) =>
                            compareBudget ? (
                              <th key={`sum${i}`} className={getBudgetClass(account, i)}>
                                <BudgetOverlay
                                  text={formatMoney(sum, 1)}
                                  tooltipText={formatMoney(Object.values(account.budgets)[i] / 1000, 0, 0)}
                                />
                              </th>
                            ) : (
                              <th key={`sum${i}`}>{formatMoney(sum, 1)}</th>
                            )
                          )}
                          <th>{formatMoney(account.ack, 1)}</th>
                        </tr>
                      ))}

                    <tr className="child-category--sum">
                      <th>Summa {child.name}</th>
                      {Object.values(child.sums).map((sum, i) => (
                        <th key={`sum${i}`}>{formatMoney(sum, 1)}</th>
                      ))}
                      <th>{formatMoney(child.ack, 1)}</th>
                    </tr>
                  </>
                )}
              </React.Fragment>
            ))}
            {category.name && (
              <tr className="parent-category--sum">
                <th>SUMMA {category.name}</th>
                {Object.values(category.sums).map((sum, i) => (
                  <th key={`sum${i}`}>{formatMoney(sum, 1)}</th>
                ))}
                <th>{formatMoney(category.ack, 1)}</th>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <th>{t("expectedResult")}</th>
          {Object.values(report.sums).map((sum, i) => (
            <th key={`sum${i}`}>{formatMoney(sum, 1)}</th>
          ))}
          <th>{formatMoney(report.ack, 1)}</th>
        </tr>
      </tfoot>
    </Table>
  );
}

export default ProfitLoss12ReportPage;
