import React, { useContext } from "react";
import { Form, Formik } from "formik";
import { Card, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as yup from "yup";

import { FormGroup } from "components/formik";
import StepHeader from "pages/companies/OnboardingWizard/steps/StepHeader";
import { OnboardingDispatchContext } from "pages/companies/OnboardingWizard/provider/OnboardingProvider";
import { SaveButton } from "pages/companies/OnboardingWizard/utils";
import { withInitialAsync } from "hooks/useAsync";
import * as companyAPI from "api/company";
import { CompanyLogoForm } from "components/forms/company-settings-forms";
import { handleFormErrors } from "api/errors";
import * as options from "api/options";

function CompanySettingsStep({ data: initialData, companyId }) {
  const { fetchDetails } = useContext(OnboardingDispatchContext);

  const formikProps = {
    initialValues: {
      ...initialData,
      default_invoice_payment_terms: options.paymentTerms.getOption(initialData.default_invoice_payment_terms),
      billecta_reminder_fee: options.invoiceReminderFees.getOption(initialData.billecta_reminder_fee),
    },
    validationSchema: yup.object().shape({
      mandrill_email_pattern: yup.string(),
      preliminary_tax: yup.number().max(0),
      next_invoice_number: yup.number().positive(),
    }),
    onSubmit: (values, { setErrors }) => {
      delete values.logo;
      return companyAPI.onboarding.companySettingsStep
        .save(companyId, {
          ...values,
          default_invoice_payment_terms: values.default_invoice_payment_terms.value,
          billecta_reminder_fee: values.billecta_reminder_fee.value,
        })
        .then(() => {
          fetchDetails(true);
        })
        .catch((error) => {
          handleFormErrors(error, setErrors);
        });
    },
  };
  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, handleSubmit }) => {
        const customerInvoiceEnabled = values.customer_invoice_enabled;
        return (
          <>
            <Card className="company-settings-step">
              <Card.Body>
                <StepHeader rightComponent={<SaveButton disabled={isSubmitting} onSave={handleSubmit} />} />
              </Card.Body>
              <Card.Body>
                <CompanySettingsForm
                  receiveEmailDomain={initialData.mandrill_receive_domain}
                  customerInvoiceEnabled={customerInvoiceEnabled}
                />
              </Card.Body>
            </Card>
            <CompanyLogoForm companyId={companyId} currentLogo={values.logo} canEditLogo />
          </>
        );
      }}
    </Formik>
  );
}

function CompanySettingsForm({ receiveEmailDomain, customerInvoiceEnabled = true }) {
  const { t } = useTranslation("company");
  const paymentTerms = options.paymentTermsOptionsWithoutDefault();
  const reminderFeeOptions = options.invoiceReminderFees.asList();
  return (
    <Form noValidate>
      <Row>
        <Col xl={2}>
          <FormGroup.Input label={t("preliminaryTax")} name="preliminary_tax" type="number" />
        </Col>
        <Col xl={4}>
          <FormGroup.MandrillReceiveEmailInput
            allowedDomain={receiveEmailDomain}
            label={t("invoiceReceiveEmail")}
            name="mandrill_email_pattern"
          />
        </Col>
      </Row>
      <section>
        <h5>{t("onboarding.invoicingInformation")}</h5>
        <hr />
        <Row xl={4}>
          <Col>
            <FormGroup.Input
              label={t("nextInvoiceNumber")}
              name="next_invoice_number"
              disabled={!customerInvoiceEnabled}
            />
          </Col>
          <Col>
            <FormGroup.SimpleSelect
              name="default_invoice_payment_terms"
              label={t("defaultInvoicePaymentTerms")}
              options={paymentTerms}
              disabled={!customerInvoiceEnabled}
            />
          </Col>
          <Col>
            <FormGroup.SimpleSelect
              name="billecta_reminder_fee"
              label={t("invoiceReminderFee")}
              options={reminderFeeOptions}
              disabled={!customerInvoiceEnabled}
            />
          </Col>
        </Row>
      </section>
      <section className="mt-3 btn-toggle-grey">
        <h5>{t("modules")}</h5>
        <hr />
        <Row xl={4}>
          <Col>
            <FormGroup.YesNoToggle
              name="offer_order_enabled"
              label={t("navigation:offerAndOrder")}
              disabled={!customerInvoiceEnabled}
            />
          </Col>
          <Col>
            <FormGroup.YesNoToggle
              name="contract_invoice_enabled"
              label={t("contractInvoicing")}
              disabled={!customerInvoiceEnabled}
            />
          </Col>
          <Col>
            <FormGroup.YesNoToggle
              name="invoice_tax_deduction_enabled"
              label={t("invoiceRotRut")}
              disabled={!customerInvoiceEnabled}
            />
          </Col>
          <Col>
            <FormGroup.YesNoToggle name="einvoice_enabled" label={t("common:einvoice")} />
          </Col>
        </Row>
        <Row xl={4}>
          <Col>
            <FormGroup.YesNoToggle name="time_reporting_enabled" label={t("timeReporting")} />
          </Col>
          <Col>
            <FormGroup.YesNoToggle name="budget_enabled" label={t("reports:budget")} />
          </Col>
        </Row>
      </section>
    </Form>
  );
}

const EnhancedCompanySettingsStep = withInitialAsync(
  CompanySettingsStep,
  ({ companyId }) => {
    return React.useCallback(
      (cancelToken) => companyAPI.onboarding.companySettingsStep.get(companyId, { cancelToken }),
      [companyId]
    );
  },
  {},
  true
);

export default EnhancedCompanySettingsStep;
