import React, { memo } from "react";
import { useTranslation } from "react-i18next";

import { SubmitButton } from "components/ui/buttons";

function ConsultSettingsSaveButtons({ isSubmitting, savePerms }) {
  const { t } = useTranslation("common");

  return (
    <>
      <SubmitButton
        isSubmitting={isSubmitting}
        type="button"
        variant="secondary"
        onClick={() => savePerms(false)}
        title={t("actions.saveAsDraft")}
        icon=""
        className=""
      />
      <SubmitButton
        title={t("actions.save")}
        isSubmitting={isSubmitting}
        type="button"
        onClick={() => savePerms(true)}
        icon=""
        className="ml-1"
      />
    </>
  );
}

export default memo(ConsultSettingsSaveButtons);
