import ReactDatePicker from "react-datepicker";
import React, { useRef } from "react";
import { DATE_FORMAT, SHORT_DATE_FORMAT } from "utils/date";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";

function DateRangeFilters({ label, dateStart, dateEnd, onChange }) {
  const { t } = useTranslation("common");
  const ref1 = useRef();
  const ref2 = useRef();
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <div className="date-range">
        <ReactDatePicker
          className="form-control"
          selected={dateStart}
          startDate={dateStart}
          endDate={dateEnd}
          dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
          monthsShown={2}
          selectsStart
          isClearable
          ref={ref1}
          placeholderText={t("starts")}
          onKeyDown={(event) => {
            if (event.keyCode === 9 || event.which === 9) {
              ref1.current.setOpen(false);
            }
          }}
          onChange={(date) => onChange({ start: date, end: dateEnd })}
        />
        <ReactDatePicker
          className="form-control"
          selected={dateEnd}
          startDate={dateStart}
          dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
          endDate={dateEnd}
          placeholderText={t("ends")}
          ref={ref2}
          onKeyDown={(event) => {
            if (event.keyCode === 9 || event.which === 9) {
              ref2.current.setOpen(false);
            }
          }}
          monthsShown={2}
          minDate={dateStart}
          selectsEnd
          isClearable
          onChange={(date) => onChange({ start: dateStart, end: date })}
        />
      </div>
    </Form.Group>
  );
}

function DateFilter({ label, defaultValue, onChange, children }) {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <ReactDatePicker
        className="form-control"
        selected={defaultValue}
        isClearable
        dateFormat={[DATE_FORMAT, SHORT_DATE_FORMAT]}
        onChange={(date) => onChange(date)}
      />
      {children}
    </Form.Group>
  );
}

function MonthDateFilter({ label, defaultValue, onChange, isClearable = true, placeholder = "", ...props }) {
  const Picker = (
    <ReactDatePicker
      className="form-control"
      selected={defaultValue}
      isClearable={isClearable}
      dateFormat={["MM/yyyy"]}
      onChange={(date) => onChange(date)}
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
      showMonthYearPicker
      placeholderText={placeholder}
      {...props}
    />
  );
  if (label) {
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        {Picker}
      </Form.Group>
    );
  }
  return Picker;
}

const today = new Date();
const minDate = new Date(today.getFullYear(), 0, 1, 0, 0, 0);
const maxDate = new Date(today.getFullYear(), 11, 31, 0, 0, 0);

function MonthOnlyDateFilter({ label, defaultValue, onChange, placeholder, isClearable = true }) {
  const Picker = (
    <ReactDatePicker
      className="form-control"
      selected={defaultValue}
      isClearable={isClearable}
      dateFormat={["MMMM"]}
      placeholderText={placeholder}
      minDate={minDate}
      maxDate={maxDate}
      onChange={(date) => onChange(date)}
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
      showMonthYearPicker
      showFullMonthYearPicker
    />
  );
  if (label) {
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        {Picker}
      </Form.Group>
    );
  }
  return Picker;
}

function YearDateFilter({ label, defaultValue, onChange, isClearable = true }) {
  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <ReactDatePicker
        className="form-control"
        selected={defaultValue}
        isClearable={isClearable}
        dateFormat="yyyy"
        onChange={(date) => onChange(date)}
        popperProps={{
          positionFixed: true, // use this to make the popper position: fixed
        }}
        yearItemNumber={6}
        showYearPicker
      />
    </Form.Group>
  );
}

function QuarterDateFilter({ label, defaultValue, onChange, isClearable = true }) {
  const Picker = (
    <ReactDatePicker
      selected={defaultValue}
      className="form-control"
      dateFormat="yyyy, QQQ"
      isClearable={isClearable}
      showQuarterYearPicker
      onChange={(date) => onChange(date)}
      popperProps={{
        positionFixed: true, // use this to make the popper position: fixed
      }}
    />
  );
  if (label) {
    return (
      <Form.Group>
        <Form.Label>{label}</Form.Label>
        {Picker}
      </Form.Group>
    );
  }
  return Picker;
}

export { DateRangeFilters, DateFilter, YearDateFilter, MonthDateFilter, MonthOnlyDateFilter, QuarterDateFilter };
