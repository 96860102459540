import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";
import { endOfMonth, startOfMonth } from "date-fns";
import { SelectSimpleFilter } from "components/filters";
import BankMatchingMonthDateFilter from "./BankMatchingMonthDateFilter";

function FetchFromBankButton({ month, fetchFromBank }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    const dateFrom = startOfMonth(month);
    const dateTo = endOfMonth(month);
    fetchFromBank(dateFrom, dateTo).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Button variant="secondary" onClick={fetch} disabled={loading}>
      {loading ? (
        <>
          <i className="fas fa-spin fa-spinner" /> {t("common:loading")}
        </>
      ) : (
        <>
          <i className="fas fa-landmark" /> {t("others:actions.fetchFromBank")}
        </>
      )}
    </Button>
  );
}

function FetchFromSKVButton({ month, fetchFromSkv }) {
  const { t } = useTranslation("common");
  const [loading, setLoading] = useState(false);

  const fetch = () => {
    setLoading(true);
    const dateFrom = startOfMonth(month);
    const dateTo = endOfMonth(month);
    fetchFromSkv(dateFrom, dateTo).finally(() => {
      setLoading(false);
    });
  };

  return (
    <Button variant="secondary" onClick={fetch} disabled={loading}>
      {loading ? (
        <>
          <i className="fas fa-spin fa-spinner" /> {t("common:loading")}
        </>
      ) : (
        <>
          <i className="fas fa-landmark" /> {t("others:actions.fetchFromSkv")}
        </>
      )}
    </Button>
  );
}

function BankMatchingFilters({
  accountOptions,
  accountsUnmatchedCount,
  fetchFromBank,
  fetchFromSkv,
  filters,
  setFilters,
  deleteMonthMatching,
  automaticMatching,
  highlightedMonths,
}) {
  const { t } = useTranslation("common");

  const accountOptionsCount = accountOptions.map((account) => {
    const count = accountsUnmatchedCount[account.number] || 0;
    if (count) {
      return {
        ...account,
        label: `${account.label} [${accountsUnmatchedCount[account.number] || 0}]`,
      };
    }
    return account;
  });

  return (
    <div className="table-filters-group">
      <section className="table-filters-left">
        <SelectSimpleFilter
          label={t("common:account")}
          defaultValue={filters.account}
          options={accountOptionsCount}
          isClearable={false}
          name="account"
          onFilter={(newFilter) => setFilters({ ...filters, ...newFilter })}
          filterOptionStartsWith
        />
        <BankMatchingMonthDateFilter
          label={t("common:dates.month")}
          defaultValue={filters.month}
          name="month"
          onChange={(d) => setFilters({ ...filters, month: d })}
          highlightedMonths={highlightedMonths}
        />
        <div className="form-group">
          <label className="d-block">{t("common:actions.show")}</label>
          <ButtonGroup aria-label="Status filter" className="status-filter">
            <Button
              variant="toggle"
              active={!filters.mode || filters.mode === "all"}
              onClick={() => setFilters({ ...filters, mode: "" })}
            >
              {t("common:statuses.all")}
            </Button>
            <Button
              variant="toggle-red"
              active={filters.mode === "unmatched"}
              onClick={() => setFilters({ ...filters, mode: "unmatched" })}
            >
              {t("common:statuses.unmatched")}
            </Button>
          </ButtonGroup>
        </div>
      </section>
      <section className="table-filters-right">
        <div className="form-group">
          <label className="d-block">{t("common:actions.actions")}</label>
          <ButtonGroup>
            <Button variant="blue" onClick={automaticMatching}>
              <i className="fas fa-code-branch" /> {t("others:actions.automaticMatching")}
            </Button>
            <Button variant="secondary" onClick={deleteMonthMatching}>
              <i className="fas fa-exclamation" /> {t("others:actions.removeMonthMatching")}
            </Button>
            {filters.account === 1630 ? (
              <FetchFromSKVButton month={filters.month} fetchFromSkv={fetchFromSkv} />
            ) : (
              <FetchFromBankButton month={filters.month} fetchFromBank={fetchFromBank} />
            )}
          </ButtonGroup>
        </div>
      </section>
    </div>
  );
}

export default BankMatchingFilters;
