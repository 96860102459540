import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { Alert, Button, ButtonGroup, Card, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Form, Formik, useFormikContext } from "formik";
import _ from "lodash";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useNavigate, useResolvedPath } from "react-router-dom";
import { addDays } from "date-fns";
import { toast } from "react-toastify";
import { NewDocuments } from "components/ui/documents/verification-documents";
import { SavedDocuments } from "components/ui/documents-refactored/documents";
import { VerificationInlineComments } from "components/modals/CommentsModal";
import * as siAPI from "api2/supplier-invoices";
import * as documentAPI from "api/document";
import * as options from "api/options";
import { formatDate, parseDate } from "utils/date";
import { AllError, CurrencyPicker, FormGroup, SupplierPicker } from "components/formik";
import { RemoveButton, SubmitButton } from "components/ui/buttons";
import { confirmExecute } from "components/modals/ConfirmModal";
import { FinancialYearDispatchContext } from "state/providers/FinancialYearProvider";
import BalanceProvider from "state/providers/BalanceProvider";
import { useCompanyState } from "hooks/useCompany";
import { useAuthState } from "hooks/useAuth";
import { codesForInventoryRegister, codesForSupplierInvoices } from "components/perms/PermCodes";
import { HasPermCode, PermCodeRequired } from "components/perms";
import { roundMoney } from "utils/money";
import {
  filterPeriodisationCostAccountsForSI,
  getPeriodisationStartDateInvalidMsg,
  periodisationAccounts,
} from "utils/periodisation";
import { enterKeyDownAsTab, filterActiveCC, filterActiveProjects } from "utils/others";
import { isAssetToAutoAddForSupplierInvoice } from "utils/assets";
import RepresentationForm from "components/forms/RepresentationForm";
import ManualBookingForm from "./ManualBookingForm";
import AutoBookingSIInfo from "./AutoBookingSIInfo";
import * as verAPI from "../../../api2/verification";
import useModal from "../../../hooks/useModal";
import { BaseVerificationsPreviewModal } from "../../modals/VerificationPreviewModals";

function formatManualBooking(values) {
  if ((values.manual_booking_enabled && values.account.number === 7399) || !values.manual_booking_enabled) {
    return {
      manual_booking_enabled: false,
      manual_booking: [],
    };
  }

  const manualBooking = values.manual_booking
    .filter((t) => !!t.account)
    .map((trans) => ({
      account: trans.account.number,
      cost_center: trans.cost_center && trans.cost_center.is_active ? trans.cost_center.value : null,
      project: trans.project && trans.project.is_active ? trans.project.value : null,
      amount: trans.debit ? trans.debit : -trans.credit,
      periodisation_config: trans.periodisation_config
        ? {
            months: trans.periodisation_config.months || null,
            date_start: formatDate(trans.periodisation_config.date_start) || null,
            pl_account_id: trans.periodisation_config?.pl_account?.value || null,
          }
        : null,
    }));
  return {
    manual_booking: manualBooking,
    manual_booking_enabled: values.manual_booking_enabled ? !!manualBooking.length : false,
  };
}

function parseManualBooking(values, accountById, projectById, centerById) {
  const booking = values.manual_booking_enabled
    ? values.manual_booking.map((trans, idx) => ({
        uid: _.uniqueId(`t${idx}`),
        account: trans.account && accountById[trans.account],
        cost_center: trans.cost_center && centerById[trans.cost_center],
        project: trans.project && projectById[trans.project],
        amount: trans.amount,
        debit: trans.amount > 0 ? trans.amount : "",
        credit: trans.amount < 0 ? Math.abs(trans.amount) : "",
        periodisation_config: trans.periodisation_config
          ? {
              ...trans.periodisation_config,
              pl_account: trans.periodisation_config ? accountById[trans.periodisation_config.pl_account_id] : null,
              date_start: trans.periodisation_config ? parseDate(trans.periodisation_config.date_start) : null,
            }
          : null,
      }))
    : [];
  booking.push({ uid: booking.length + 1 });
  return booking;
}

function RescanAzoraBtn({ companyId, itemId, updateInvoice, setFieldValue }) {
  const [loading, setLoading] = useState(false);
  const {
    accounts: { byId: accountById },
    currencies: { byId: currencies },
  } = useCompanyState();
  const { t } = useTranslation("common");
  const onScan = () => {
    setLoading(true);
    siAPI
      .rescanInvoice(companyId, itemId)
      .then((response) => {
        const output = { ...response.data };
        output.manual_booking_enabled = response.data.manual_booking.length > 0;
        output.manual_booking = [];
        output.booking_date = parseDate(response.data.booking_date);
        output.due_date = parseDate(response.data.due_date);
        output.amount_currency = currencies[response.data.amount_currency || "SEK"];
        output.periodisation_date_start = parseDate(response.data.periodisation_date_start);
        output.periodisation_account = accountById[response.data.periodisation_account_id] ?? "";
        delete output.periodisation_account_id;
        if (response.data.account_id) {
          output.account = accountById[response.data.account_id];
        } else {
          output.account = null;
        }
        if (response.data.supplier_id && response.data.local_supplier) {
          output.supplier = { ...response.data.local_supplier, supplier_id: response.data.supplier_id };
        }
        updateInvoice(output);
        if (response.data.manual_booking.length > 0) {
          response.data.manual_booking_enabled = true;
          updateInvoice({
            manual_booking_enabled: true,
            manual_booking: parseManualBooking(response.data, accountById),
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Button variant="secondary" onClick={onScan} disabled={loading}>
      {t("rescanInvoice")}
    </Button>
  );
}

function SupplierInvoiceForm({ invoice, company, onSave, children }) {
  const { t, i18n } = useTranslation("si");

  const {
    accounts: { byId: accountById, asOptions: accountOptions },
    costCenters: { byId: centerById, asOptions: centerOptions },
    projects: { byId: projectById, asOptions: projectOptions },
    currencies: { byId: currencies },
  } = useCompanyState();
  const { checkYears } = useContext(FinancialYearDispatchContext);
  const editDisabled = !["", "prelim", "disputed", "investigate"].includes(invoice.sub_status);
  const [newFiles, setNewFiles] = useState([{ key: _.uniqueId("nd.") }]);
  const newDueDate = addDays(invoice.booking_date, 30);
  const verPreviewModal = useModal();

  const initialAmountCurrency = {
    value: currencies[invoice.amount_currency].value,
    label:
      i18n.language === "sv"
        ? currencies[invoice.amount_currency].label_sv
        : currencies[invoice.amount_currency].label_en,
  };
  const repActivities = options.repActivities.asList();
  const repPurposes = options.repPurposes.asList();
  const defaultActivity = repActivities[0];
  const defaultPurpose = repPurposes[0];

  const { user } = useAuthState();
  const isConsult = user.is_superuser || user.is_consult;
  const initialAccounts = isConsult ? [2440, 2640] : [];
  const isThisRepresentation = (account) => {
    return account && [6071, 6072].includes(account.number);
  };
  const formikProps = {
    initialValues: {
      ...invoice,
      due_date: invoice.due_date ? invoice.due_date : newDueDate,
      // eslint-disable-next-line no-nested-ternary
      vat_amount: company.vat_disabled ? 0 : invoice.vat_amount || 0,
      account: invoice.account ? accountById[invoice.account] : null,
      manual_booking: parseManualBooking(invoice, accountById, projectById, centerById),
      periodisation_account: invoice.periodisation_account ? accountById[invoice.periodisation_account] : null,
      supplier: invoice.local_supplier && {
        value: invoice.local_supplier.supplier_id,
        label: invoice.local_supplier.supplier_name,
        ...invoice.local_supplier,
      },
      project: invoice.project && projectById[invoice.project].is_active ? projectById[invoice.project] : null,
      cost_center:
        invoice.cost_center && centerById[invoice.cost_center].is_active ? centerById[invoice.cost_center] : null,
      amount_currency: initialAmountCurrency,
      representation: !_.isEmpty(invoice.representation)
        ? {
            activity: options.repActivities.getOption(invoice.representation.activity),
            purpose: options.repPurposes.getOption(invoice.representation.purpose),
            participants: invoice.representation.participants
              ? invoice.representation.participants.filter((p) => !!p.name.trim() && !!p.company.trim())
              : [],
          }
        : {
            activity: defaultActivity,
            purpose: defaultPurpose,
            participants: [],
          },
    },

    validationSchema: yup
      .object()
      .shape({
        supplier: yup.object().nullable().required(),
        booking_date: yup.date().nullable().required(),
        due_date: yup
          .date()
          .nullable()
          .required()
          .when("booking_date", ([startDate], schema) => {
            return startDate ? schema.min(startDate, t("common:errors.mustLaterBookingDate")) : schema;
          }),
        account: yup.object().nullable().required(),
        amount: yup.number().required(),
        invoice_no: yup.string().required(),
        ocr: yup.string(),
        cost_center: yup
          .object()
          .nullable()
          .when("account", ([account], schema, { parent }) => {
            if (parent.manual_booking_enabled) {
              return schema;
            }
            return account && account.cost_center_option === "mandatory" ? schema.required() : schema;
          }),
        project: yup
          .object()
          .nullable()
          .when("account", ([account], schema, { parent }) => {
            if (parent.manual_booking_enabled) {
              return schema;
            }
            return account && account.project_option === "mandatory" ? schema.required() : schema;
          }),
        periodisation_months: yup
          .number()
          .min(1)
          .max(60)
          .when("periodisation_enabled", ([periodisation], schema) => {
            return periodisation ? schema.required() : schema;
          }),
        periodisation_date_start: yup
          .date()
          .nullable()
          .when("periodisation_enabled", ([periodisation], schema) => {
            return periodisation ? schema.required() : schema;
          }),
        periodisation_account: yup
          .object()
          .nullable()
          .when("periodisation_enabled", ([periodisation], schema) => {
            return periodisation ? schema.required() : schema;
          }),
      })
      .test("period_date_start", function (values) {
        if (values.periodisation_enabled && values.periodisation_date_start && values.booking_date) {
          const { booking_date } = values;
          const error_msg = getPeriodisationStartDateInvalidMsg(t, values.periodisation_date_start, booking_date, true);
          if (error_msg) {
            // earliest day is first day of 1 month before from booking_date
            return this.createError({
              path: "periodisation_date_start",
              message: error_msg,
            });
          }
        }
        return true;
      })
      .test("project", function (values) {
        if (
          values.periodisation_enabled &&
          values.periodisation_date_start &&
          values.periodisation_months &&
          values.project &&
          values.project.date_end
        ) {
          const finishDateOfPeriodisation = new Date(values.periodisation_date_start);
          finishDateOfPeriodisation.setMonth(finishDateOfPeriodisation.getMonth() + values.periodisation_months - 1);
          const projectEndDate = new Date(values.project.date_end);
          if (finishDateOfPeriodisation > projectEndDate) {
            return this.createError({
              path: "project",
              message: t("ver:errors.periodisationFinishAfterProjectEnds"),
            });
          }
        }
        return true;
      }),
    onSubmit: async (values, { setErrors, setFieldValue }) => {
      const verificationPreviewOnly = !!values.verification_preview;
      const executeAnyway = await checkYears(values.booking_date);
      if (!executeAnyway) {
        return;
      }
      const isDuplication = !verificationPreviewOnly
        ? await siAPI
            .checkDuplicates(company.id, {
              invoice_id: invoice.id || null,
              supplier_id: values.supplier.supplier_id || values.supplier.value,
              invoice_no: values.invoice_no,
              ocr: values.ocr,
            })
            .then((response) => response.data.has_duplicates)
        : false;
      if (isDuplication) {
        const answer = await confirmExecute(t("confirm.allowDuplicate"));
        if (!answer) {
          return;
        }
      }
      let costAccount = values.account.value;
      if (values.manual_booking_enabled) {
        costAccount =
          values.manual_booking.length >= 2 && !parseFloat(values.vat_amount)
            ? values.manual_booking[1].account?.number
            : values.manual_booking[2].account?.number;
      }
      let representation = null;
      if (isThisRepresentation(values.account)) {
        const activityValue = values.representation.activity && values.representation.activity.value;
        const purposeValue = values.representation.purpose && values.representation.purpose.value;
        const participants = values.representation.participants.filter((p) => p.name.trim() && p.company.trim());
        representation = {
          activity: activityValue,
          purpose: purposeValue,
          participants,
        };
      }
      const formatedPayload = () => {
        return {
          ...values,
          id: invoice.id,
          supplier: values.supplier.supplier_id,
          account: costAccount,
          booking_date: formatDate(values.booking_date),
          due_date: formatDate(values.due_date),
          amount_currency: values.amount_currency.value,
          vat_amount: roundMoney(values.vat_amount || 0, 2),
          vat_amount_currency: values.amount_currency.value,
          cost_center: values.cost_center && values.cost_center.value,
          project: values.project && values.project.value,
          ...formatManualBooking(values),
          periodisation_months: values.periodisation_enabled ? values.periodisation_months : 1,
          periodisation_date_start: values.periodisation_enabled ? formatDate(values.periodisation_date_start) : null,
          periodisation_account: values.periodisation_enabled ? values.periodisation_account.value : null,
          representation,
        };
      };
      if (!verificationPreviewOnly) {
        await siAPI
          .save(company.id, formatedPayload())
          .then(async (response) => {
            const _newFiles = newFiles.filter((d) => !!d.file).reduce((d, { file }) => [...d, file], []);
            if (_newFiles.length) {
              await verAPI.document.uploadMany(company.id, response.data.id || invoice.id, _newFiles).catch(() => {
                // pass silently
              });
            }
            toast.success(t("msg:saved"), { autoClose: 2000 });
            if (onSave) {
              onSave({ ...values, ...response.data });
            }
          })
          .catch((error) => {
            toast.error(t("msg:fixErrors"));
            setErrors(error.data);
          });
      } else {
        setFieldValue("verification_preview", false);
        await siAPI
          .previewVerifications(company.id, formatedPayload())
          .then(async (response) => {
            verPreviewModal.open({ verifications: response.data });
          })
          .catch((error) => {
            setErrors(error.data);
            toast.error(t("msg:fixErrors"));
          });
      }
    },
  };

  function onSupplierChange(supplier, setFieldValue) {
    setFieldValue("deactivate_payment", supplier.deactivate_payment || false);
    setFieldValue("account", accountById[supplier.contra_account]);
  }

  function filterPeriodisationAccounts(option) {
    return periodisationAccounts.includes(option.id);
  }

  const activeProjects = projectOptions.filter(filterActiveProjects);
  const activeCenters = centerOptions.filter(filterActiveCC);
  const showProjects = activeProjects.length !== 0;
  const showCostCenter = activeCenters.length !== 0;
  const optionsPeriodisationAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationAccounts);
  }, [accountOptions]);
  const optionsPerCostAccount = useMemo(() => {
    return accountOptions.filter(filterPeriodisationCostAccountsForSI);
  }, [accountOptions]);
  const showMoveToArchiveBtn = invoice.id && invoice.sub_status === "" && invoice.documents.length > 0;

  function onAccountChange(account, setFieldValue) {
    if (account && isThisRepresentation(account)) {
      setFieldValue("representation", {
        activity: repActivities[account.number === 6071 ? 2 : 0],
        purpose: repPurposes[0],
        participants: [],
      });
    }
  }

  const netAmountSek = (values) => {
    if (!values.amount) {
      return null;
    }
    const exchangeRate = values.amount_currency.rate || 1;
    return (values.amount - values.vat_amount) * exchangeRate;
  };
  const isNonDeductibleRepresentationOn6071 = (values) => {
    if (!values.amount) {
      return false;
    }
    if (!values.account || values.account.number !== 6071) {
      return false;
    }
    const participants = values.representation.participants.filter((p) => p.name.trim() && p.company.trim());
    return netAmountSek(values) / participants.length > 60;
  };

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, setValues, setFieldValue }) => {
        return (
          <Form id="si-form" noValidate onKeyDown={enterKeyDownAsTab}>
            <Card>
              <Card.Body>
                <Row>
                  <Col xl={6}>
                    <Row>
                      <Col>
                        <SupplierPicker
                          name="supplier"
                          supplier={values.supplier}
                          setFieldValue={setFieldValue}
                          companyId={company.id}
                          onChange={(supplier) => onSupplierChange(supplier, setFieldValue)}
                          label={t("common:supplier")}
                          required
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <FormGroup.BookingDatePicker
                          label={t("common:dates.invoiceDate")}
                          name="booking_date"
                          required
                        />
                      </Col>
                      <Col md={3}>
                        <DueDateAutoInput />
                      </Col>
                      <Col md={3}>
                        <FormGroup.Input label={t("common:invoiceNo")} name="invoice_no" required />
                      </Col>
                      <Col md={3}>
                        <FormGroup.Input label="OCR" name="ocr" />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <FormGroup.MoneyCalculationInput
                          label={t("common:money.amount")}
                          name="amount"
                          disabled={editDisabled}
                          required
                        />
                      </Col>
                      <Col md={3}>
                        <FormGroup.MoneyCalculationInput
                          label={t("common:money.vatAmount")}
                          name="vat_amount"
                          disabled={editDisabled || company.vat_disabled}
                        />
                      </Col>
                      <Col md={6}>
                        <CurrencyPicker
                          name="amount_currency"
                          disabled={editDisabled}
                          currentCode={values.amount_currency.value}
                          currentDate={values.booking_date}
                          setFieldValue={setFieldValue}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col>
                        <Card.Title>{t("common:options")}</Card.Title>
                        <FormGroup.Checkbox label={t("deactivatePayment")} name="deactivate_payment" />
                        <FormGroup.Checkbox
                          label={t("common:periodisation")}
                          name="periodisation_enabled"
                          disabled={values.manual_booking_enabled}
                        />
                        <FormGroup.Checkbox
                          label={t("manualBooking")}
                          name="manual_booking_enabled"
                          disabled={values.periodisation_enabled}
                        />
                        {values.periodisation_enabled && (
                          <div className="p-1">
                            <Row>
                              <Col xl={3}>
                                <FormGroup.Input
                                  type="number"
                                  max={60}
                                  min={1}
                                  name="periodisation_months"
                                  label={t("perMonthsNo")}
                                  required
                                />
                              </Col>
                              <Col xl={3}>
                                <FormGroup.DatePicker
                                  name="periodisation_date_start"
                                  label={t("ver:perStartOn")}
                                  required
                                />
                              </Col>
                              <Col xl={6}>
                                <FormGroup.SimpleSelect
                                  name="periodisation_account"
                                  label={`${t("common:periodisationAccount")}`}
                                  options={optionsPeriodisationAccount}
                                  required
                                />
                              </Col>
                            </Row>
                          </div>
                        )}
                      </Col>
                    </Row>
                    {!values.manual_booking_enabled && (
                      <Row>
                        <Col xl={4}>
                          <FormGroup.SimpleSelect
                            name="account"
                            label={t("common:costAccount")}
                            options={values.periodisation_enabled ? optionsPerCostAccount : accountOptions}
                            required
                            onChange={(selected) => onAccountChange(selected, setFieldValue)}
                            filterOptionStartsWith
                          />
                          {values.account && values.account.number === 5615 && (
                            <FormGroup.Checkbox label={t("book50VAT")} name="account_5615_half_vat" />
                          )}
                        </Col>
                        {((values.account && values.account.project_option === "mandatory") || showProjects) && (
                          <Col xl={4}>
                            <FormGroup.SimpleSelect
                              name="project"
                              label={t("common:project")}
                              options={activeProjects}
                              isClearable
                              required={values.account && values.account.project_option === "mandatory"}
                            />
                          </Col>
                        )}
                        {((values.account && values.account.cost_center_option === "mandatory") || showCostCenter) && (
                          <Col xl={4}>
                            <FormGroup.SimpleSelect
                              name="cost_center"
                              label={t("common:costCenter")}
                              options={centerOptions.filter(filterActiveCC)}
                              isClearable
                              required={values.account && values.account.cost_center_option === "mandatory"}
                            />
                          </Col>
                        )}
                      </Row>
                    )}
                    {values.account && (
                      <Row>
                        <Col>
                          <AutoBookingSIInfo costAccountNumber={values.account.number} />
                        </Col>
                      </Row>
                    )}
                    {values.manual_booking_enabled && (
                      <Row>
                        <Col>
                          <BalanceProvider initialAccounts={initialAccounts} companyId={company.id}>
                            <ManualBookingForm />
                          </BalanceProvider>
                        </Col>
                      </Row>
                    )}
                    {!values.manual_booking_enabled && values.account && isThisRepresentation(values.account) && (
                      <Row>
                        <Col>
                          <RepresentationForm
                            t={t}
                            isNonDeductibleRepresentationOn6071={isNonDeductibleRepresentationOn6071(values)}
                          />
                        </Col>
                      </Row>
                    )}
                    {invoice.id && (
                      <VerificationInlineComments companyId={company.id} itemId={invoice.id} allowCreate />
                    )}
                    <div className="mt-3">
                      <hr />
                      {HasPermCode(codesForInventoryRegister.enabled) && isAssetToAutoAddForSupplierInvoice(values) && (
                        <Alert variant="info">{t("afterSubmitInfoWithAssetRedirection")}</Alert>
                      )}
                      {invoice.id && invoice.has_assets && (
                        <Alert variant="warning">{t("beforeSubmitHasAssetsInfo")}</Alert>
                      )}
                      <AllError errors={errors} />
                      {invoice.documents && !!invoice.documents.length && (
                        <Row>
                          <Col className="mb-2">
                            <RescanAzoraBtn
                              companyId={company.id}
                              itemId={values.id}
                              setFieldValue={setFieldValue}
                              updateInvoice={(newValues) =>
                                setValues({
                                  ...values,
                                  ...newValues,
                                })
                              }
                            />
                          </Col>
                        </Row>
                      )}
                      {!!children &&
                        children({
                          showMoveToArchiveBtn,
                          isSubmitting,
                          setFieldValue,
                        })}
                    </div>
                  </Col>
                  <Col xl={6} className="verification-files">
                    {invoice.id && (
                      <SavedDocuments
                        companyId={company.id}
                        partUrl={`/verifications/${invoice.id}/documents`}
                        documents={invoice.documents}
                      />
                    )}
                    <NewDocuments
                      documents={newFiles}
                      multiple
                      onChange={({ file, index }) =>
                        documentAPI.onNewFileChange({
                          file,
                          index,
                          allFiles: newFiles,
                          setFile: setNewFiles,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            {verPreviewModal.show && (
              <BaseVerificationsPreviewModal
                handleClose={verPreviewModal.close}
                verifications={verPreviewModal.data.verifications}
                isLoading={false}
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
}

function NextDraftInvoiceBtn({ companyId, invoiceId, invoiceStatus }) {
  const { t } = useTranslation("si");
  const navigate = useNavigate();
  const currentPath = useResolvedPath("").pathname;
  const getNextDraftInvoice = async () => {
    const { next } = await siAPI.getNextDraft(companyId, invoiceId);
    if (next) {
      const nextLoc = currentPath.replace(invoiceId, next.id);
      navigate("/temp", { replace: true });
      navigate(nextLoc, { replace: true });
    } else {
      toast.error("No more draft invoices");
    }
  };
  if (invoiceId && invoiceStatus === 0) {
    return (
      <PermCodeRequired code={codesForSupplierInvoices.manage}>
        <Button onClick={getNextDraftInvoice} className="mr-2" variant="secondary">
          {t("nextDraftInvoice")}
        </Button>
      </PermCodeRequired>
    );
  }
  return null;
}

function SaveAsDraftBtn({ invoiceStatus, isSubmitting, setFieldValue }) {
  if (invoiceStatus === 0) {
    return (
      <SubmitButton
        variant="draft"
        icon="fa-sticky-note"
        className="mr-2"
        isSubmitting={isSubmitting}
        title="common:actions.saveAsDraft"
        onClick={() => {
          setFieldValue("status", 0);
        }}
      />
    );
  }
  return null;
}

function MoveToFileArchiveBtn({ companyId, invoice, showMoveToArchiveBtn, postDeleteFn }) {
  const { t } = useTranslation("si");
  const onMoveArchive = async () => {
    const answer = await confirmExecute(t("confirm.moveArchive", { invoiceNo: invoice.invoice_no || invoice.ocr }));
    if (answer) {
      siAPI
        .moveToArchiveDocument(companyId, invoice.id, true)
        .then(async (response) => {
          const { next } = await siAPI.getNextDraft(companyId, invoice.id);
          postDeleteFn({ ...invoice, next });
        })
        .catch((error) => {
          toast.error(t("msg:canNotExecuteAction"));
        });
    }
  };
  if (showMoveToArchiveBtn) {
    return (
      <Button variant="red" onClick={onMoveArchive}>
        <i className="fas fa-reply" /> {t("common:actions.moveArchive")}
      </Button>
    );
  }
  return null;
}

function RemoveBtn({ companyId, invoice, postDeleteFn }) {
  const { t } = useTranslation("si");

  const onDelete = async () => {
    siAPI
      .remove(companyId, invoice.id)
      .then(async (response) => {
        toast.success(t("msg:deleted"));
        const { next } = await siAPI.getNextDraft(companyId, invoice.id);
        postDeleteFn({ ...invoice, next });
      })
      .catch((error) => {
        toast.error(t("msg:canNotExecuteAction"));
      });
  };
  if (invoice.id && invoice.sub_status === "") {
    return (
      <RemoveButton
        onClick={onDelete}
        confirmMessage={t("confirm.remove", {
          invoiceNo: invoice.invoice_no || invoice.ocr || "-",
        })}
      />
    );
  }
  return null;
}

function SupplierInvoiceFormWithActions({ invoice, company, onSave, postDeleteFn }) {
  const { t } = useTranslation("ver");

  return (
    <SupplierInvoiceForm invoice={invoice} company={company} onSave={onSave}>
      {({ showMoveToArchiveBtn, isSubmitting, setFieldValue }) => (
        <Row>
          <Col xl={showMoveToArchiveBtn ? 7 : 8}>
            <ButtonGroup>
              <NextDraftInvoiceBtn companyId={company.id} invoiceId={invoice.id} invoiceStatus={invoice.status} />
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{t("ver:previewVer")}</Tooltip>}>
                <Button
                  type="submit"
                  className="mr-2"
                  variant="toggle"
                  isSubmitting={isSubmitting}
                  onClick={() => {
                    setFieldValue("verification_preview", true);
                  }}
                >
                  <i className="mdi mdi-file-search-outline" /> {t("ver:verShort")}
                </Button>
              </OverlayTrigger>
              <SaveAsDraftBtn
                invoiceStatus={invoice.status}
                isSubmitting={isSubmitting}
                setFieldValue={setFieldValue}
              />
              <SubmitButton
                isSubmitting={isSubmitting}
                onClick={() => {
                  setFieldValue("status", 1);
                }}
                title="actions.sendForApproval"
              />
            </ButtonGroup>
          </Col>
          <Col xl={showMoveToArchiveBtn ? 5 : 4}>
            <ButtonGroup className="float-right">
              <MoveToFileArchiveBtn
                showMoveToArchiveBtn={showMoveToArchiveBtn}
                invoice={invoice}
                companyId={company.id}
                postDeleteFn={postDeleteFn}
              />
              <RemoveBtn companyId={company.id} invoice={invoice} postDeleteFn={postDeleteFn} />
            </ButtonGroup>
          </Col>
        </Row>
      )}
    </SupplierInvoiceForm>
  );
}

function DueDateAutoInput() {
  const { t } = useTranslation("common");
  const { values, setFieldValue } = useFormikContext();
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      didMountRef.current = true;
    } else {
      if (!values.booking_date) {
        return;
      }
      const newDate = addDays(
        values.booking_date,
        values.supplier ? parseInt(values.supplier.payment_terms, 10) || 30 : 30
      );
      setFieldValue("due_date", newDate);
    }
  }, [values.booking_date, values.supplier, setFieldValue]);

  return <FormGroup.DatePicker label={t("common:dates.dueDate")} name="due_date" required />;
}

export { SupplierInvoiceForm, SaveAsDraftBtn, RemoveBtn, MoveToFileArchiveBtn };
export default SupplierInvoiceFormWithActions;
